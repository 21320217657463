.table-responsive {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(195, 194, 197, 0.4);
  }
  .record-list-table {
    width: 100%;
    thead {
      tr {
        margin-top: 2px;
        th {
          padding: 0.8rem 1.4rem;
          color: rgba(255, 255, 255, 0.5);
          background-color: $brand-primary;
          border-bottom: 1px solid $gray-500;
          font-size: 0.875rem;
          font-weight: normal;
          white-space: nowrap;
          text-align: left;
          vertical-align: middle;
          transition: 0.3s ease-in-out;
          border-radius: 10px;
          .sorting {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ico {
              cursor: pointer;
              pointer-events: all;
              visibility: visible;
              opacity: 0;
              transition: all 0.3s ease-in-out;
              color: white;
            }
          }
          &:hover {
            background-color: $sidebar-hover-bg;
            .ico {
              pointer-events: all;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .action-col {
          width: 10%;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 1.3rem 25px;
          color: $input-value;
          background-color: $brand-primary;
          font-size: 0.875rem;
          white-space: nowrap;
          border-bottom: 1px solid $gray-500;
          text-align: left;
          vertical-align: middle;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .record-list-table thead tr th {
    font-size: 0.813rem !important;
  }
  .record-list-table tbody tr td {
    font-size: 0.813rem !important;
  }
  .table-responsive {
    overflow: auto;
    max-width: 624px !important;
  }
}
@media only screen and (max-width: 1440px) {
  .table-responsive {
    overflow: auto;
    max-width: 1030px;
  }
}
@media only screen and (max-width: 1990px) and (min-width: 1450px) {
  .table-responsive {
    overflow: auto;
    max-width: 1510px;
  }
}
@media only screen and (max-width: 2225px) and (min-width: 2100px) {
  .table-responsive {
    overflow: auto;
    max-width: 1691px;
  }
}
@media (max-width: 768px) {
  .table-responsive {
    // max-width: 550px !important;
    overflow: auto;
  }
}
