.activity-item__main {
  padding: #{$spacing-md} 0;
  
  .activity-item--inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: #{$spacing-sm};
    
    .activity-icon--block {
      border: #{$border-width-sm} solid #{$input-border};
      height: #{$size-lg};
      width: #{$size-lg};
      border-radius: #{$border-radius-lg};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: #{$font-size-sm};
      padding: #{$spacing-xs};
      margin-right: #{$spacing-md};
      
      .activity_icon__inner {
        position: relative;
        padding: #{$spacing-xs};
        
        .activity--icon {
          color: #{$sidebar-menu-item};
          
          svg {
            height: #{$size-md};
            width: #{$size-md};
          }
        }
        
        .activity-status--icon {
          svg {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
    
    .activity-content__main-block {
      flex: 1;
      width: 100%;
      border-bottom: #{$border-width-sm} solid #{$sidebar-menu-item};
      
      .activity-content--inner {
        display: flex;
        align-items: center;
        margin-bottom: #{$spacing-md};
        
        .activity-details--content-block {
          display: flex;
          flex-direction: column;
          
          .activity-details-block {
            font-size: #{$font-size-xs};
            font-weight: #{$font-weight-regular};
            margin-bottom: #{$spacing-sm};
            
            .meta-info {
              color: #{$sidebar-menu-item};
            }
          }
          
          .activity-timing {
            color: #{$sidebar-menu-item};
            font-size: #{$font-size-xs};
            font-weight: #{$font-weight-regular};
          }
        }
        
        .activity-item__right-block {
          margin-left: auto;
          color: #{$button-secondary-text};
          
          .update-button {
            padding: #{$spacing-sm} #{$spacing-lg};
            background-color: #{$button-secondary};
            color: #{$button-secondary-text};
            border-radius: #{$border-radius-sm};
            font-size: #{$font-size-sm};
            font-weight: #{$font-weight-medium};
          }
        }
      }
    }
  }
}