// userGroup list

.user-group-page_main {
  .user-group-main-block {
    .user-group-block {
      .user-group-search-box {
        .user-group-btn {
          padding: 0.8rem 1rem;
        }
        .import-user--block {
          display: flex;
          align-items: center;
          .upload-file-block {
            .upload-file--input {
              display: none;
            }
            .upload-file-inner {
              cursor: pointer;
              .upload-file-text {
                font-style: normal;
                font-weight: bold;
                font-size: 0.875rem;
                line-height: 1.125rem;
                color: #e12f17;
                padding-left: 0.8rem;
              }
              .import-btn__block {
                .btn:focus {
                  outline: none;
                  box-shadow: none;
                }
                .import_btn {
                  padding: 0.8rem 1rem;
                  border-color: $brand-secondary;
                  color: #e12f17;
                  border-radius: 15px;
                  text-transform: capitalize;
                  background: $brand-secondary;
                  .user-upload-file-box-icon {
                    color: #e12f17;
                    margin-right: 1rem;
                  }
                  .user-upload-file-box-text {
                    font-style: normal;
                    line-height: 1.25rem;
                    color: #e12f17;
                    font-weight: 400;
                  }
                }
              }
            }
          }
          .download-sample--block {
            display: flex;
            align-items: center;
            margin-left: 1rem;
            .download-sample-file {
              font-size: 0.9rem;
              color: #58d37d;
              cursor: pointer;
              font-weight: 500;
            }
          }
        }
      }
      .user-group-table {
        .table-responsive {
          .record-list-table {
            .charger-link {
              cursor: pointer;
            }
            .charger-link:hover {
              text-decoration: underline;
            }
            .user-group-icon {
              // color: lightskyblue;
              margin-right: 1rem;
              cursor: pointer;
            }
            .user-group-table-editIcon {
              margin-right: 1.5rem;
              font-size: 1.2rem;
              color: $semantic-info;
              cursor: pointer;
            }
            .user-group-table-deleteIcon {
              font-size: 1.2rem;
              color: $brand-accent;
              cursor: pointer;
            }
            .user-group-delete-Btn {
              padding: 0.8rem 1rem;
            }
          }
        }
      }
    }
  }
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//userGroup Form

.user-group-form-page-main {
  .user-group-form-card {
    .card-body {
      padding: 2rem 2.5rem;
    }
    .user-group-form__block {
      padding-bottom: 2rem;
      .user-group-form__inner {
        padding: 1rem 0;
        .isChargecoinDiscountEligible-field-main--block {
          margin-top: 2rem;
          .isChargecoinDiscountEligible-checkbox-input__block {
            .isChargecoinDiscountEligible--label {
              font-size: 1rem;
              margin-bottom: 0.7rem;
              color: $input-label;
              font-weight: 500;
              margin-right: 1rem;
            }
          }
        }
        .isCashbackDiscountEligible-field-main--block {
          margin-top: 2rem;
          .isCashbackDiscountEligible-checkbox-input__block {
            .isCashbackDiscountEligible--label {
              font-size: 1rem;
              margin-bottom: 0.7rem;
              color: $input-label;
              font-weight: 500;
              margin-right: 1rem;
            }
          }
        }
      }
      .user-group-form-save--btn--block {
        .user-group-form-save-btn {
          padding: 0.8rem 1rem;
        }
      }
    }
  }
}

//user Form css
.user-form-body__block {
  padding: 1rem 2rem;
  .user-form--block {
    .user-form-btn__block {
      margin-top: 1rem;
      .user-form-btn {
        padding: 1rem 1.5rem;
        font-weight: 500;
      }
    }
  }
}
