.serverOperation-title-name {
  margin-bottom: 1.5rem;
}
.serverOperation-content-wrapper {
  background: $gray-700;
  .serverOperation-form-main {
    .card-body {
      padding: 2rem 2.5rem;
    }
    .serverOperation-form__block {
      padding-bottom: 2rem;
      .server-flex {
        display: flex;
        gap: 10px;
      }
      .serverOperation-form__inner {
        padding: 1rem 0;
        .import--btn {
          display: flex;
          padding-left: 1.5rem;
        }
        .serverOperation-form--block {
          padding: 1rem 0;
          .serverOperation-block__field-inputs {
            .field--btn {
              border-color: transparent;
              background-color: transparent;
              color: $brand-accent;
              font-size: 1rem;
              padding: 0;
              font-weight: 600;
              display: flex;
              align-items: center;
              &:focus,
              &:active {
                box-shadow: none;
              }
              .plus--icon {
                margin-right: 0.2rem;
              }
            }
          }
        }
        .download-sample--block {
          display: flex;
          align-items: center;
          .download-sample-file {
            font-size: 0.9rem;
            color: $special-excel;
            cursor: pointer;
            font-weight: 500;
          }
        }
      }
      .serverOperation-save--btn--block {
        .serverOperation-save-btn {
          padding: 0.8rem 1rem;
        }
      }
    }
  }
}
