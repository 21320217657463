.chargeSpeedList-page {
  .chargeSpeedList-page-wrapper {
    .chargeSpeedList-search-box {
      display: flex;
      align-items: center;
      .chargeSpeedList-search-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .chargeSpeedList-search-box-Btn {
          padding: 0.8rem 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .chargeSpeedList-table {
      .chargeSpeedList-table-editIcon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $semantic-info;
        cursor: pointer;
      }
      .chargeSpeedList-table-deleteIcon {
        font-size: 1.2rem;
        color: $brand-accent;
        cursor: pointer;
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
// Form css
.chargeSpeed-form-page__main {
  .chargeSpeed-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $tab-active-color;
    .chargeSpeed-header-name {
      font-size: 1.25rem;
    }
    .close___btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $light-table-header;
      }
    }
  }
  .chargeSpeed-form-body__block {
    padding: 1rem 2rem;
    .chargeSpeed-form--block {
      .form-btn__block {
        text-align: right;
        margin-top: 1rem;
        .form_btn {
          padding: 0.8rem 1rem;
          font-weight: 500;
        }
        .cancel____btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $brand-accent;
          &:hover {
            background-color: $button-secondary;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
