.select-all-checkbox-input__block {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    .select-all--label {
      font-size: 1rem;
    //   margin-bottom: 0.7rem;
      font-weight: 500;
      margin-right: 1rem;
      color: $input-label;
    }
  }

.station-access-list__main {
    // margin-top: 3rem;
  .station-access-list--tabs {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 12px;
    gap: 6px;
    .station-access-status-tab {
      padding: 0.7rem 1.5rem;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8em;
      // line-height: 1.25rem;
      color: $gray-200;
      margin-right: 0px;
      cursor: pointer;
      display: flex;
      &.active {
        background: rgba(60, 124, 221, 0.13);
        border-radius: 15px;
        padding: 0.7rem 1.5rem;
        color: $input-value;
        font-size: 0.8em;
        margin-right: 0px;
        font-style: normal;
        font-weight: normal;
      }
      .station-access-count--badge {
        width: 21px;
        height: 21px;
        display: inline-block;
        padding: 4px;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 1rem;
        background: #0f9b72;
        margin-left: 0.5rem;
      }
      .draft-badge {
        background: $semantic-info;
      }
    }
  }
  .station-access-list--block {
    margin-top: 5px;
    .station-access--list {
      background: $brand-primary;
      border: 1.023px solid #1e3249;
      box-sizing: border-box;
      border-radius: 20.453px;
      padding: 0.3rem 1.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      //   cursor: pointer;
      //   &:hover {
      //     background-color: $gray-600;
      //   }
      .list-info {
        margin-right: auto;
        flex: 1;
        width: auto;
        .list-name {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $input-label;
        }
        .charger-list-info {
          display: flex;
          //   margin-top: 1rem;

          .charger-checkbox-input__block {
            margin-top: 0.2rem;
            .otp-checkbox {
              display: flex;
              justify-content: flex-start;
            }
            .charger--label {
              font-size: 0.9rem;
              margin-bottom: 0.7rem;
              font-weight: 500;
              margin-right: 0.5rem;
            }
          }
        }

        .station-checkbox-input__block {
          margin-top: 1rem;
          .station--label {
            font-size: 1rem;
            margin-bottom: 0.7rem;
            font-weight: 500;
            margin-right: 1rem;
            color: $input-label;
          }
        }
      }
      .station-access-details--block {
        display: flex;
        flex: 1;
        place-items: center;
        .station-access-status--block {
          display: inline-flex;
          align-items: center;
          margin-left: 1rem;
          .station-access-details--status {
            display: flex;
            align-items: center;
            flex-direction: column;
            .disabled-list-text {
              color: #a5b6c6;
              font-style: normal;
              font-weight: 500;
              font-size: 0.9rem;
            }
            .list-dot-icon {
              display: inline-flex;
              font-size: 1.5rem;
            }
            .list-text {
              font-style: normal;
              font-weight: 500;
              font-size: 0.9rem;
            }
          }
        }
        .plug-info--block {
          margin-left: 1rem;
          // background-color: red;
          .plug-info {
            padding-right: 0.8rem;
            // background-color: yellow;
          }
          .plug-info-wrapper {
            .plug-info--inner {
              box-sizing: border-box;
              // background-color: #14ac7f;
              padding: 0.7rem 1rem;
              display: flex;
              align-items: center;
              margin-left: 10px;
              .plug-info-content {
                display: flex;
                align-items: center;
                margin-right: 1rem;
                width: auto;
                // background-color: yellow;
                margin-left: 2rem;
                .station-access-round_icon {
                  margin-right: 0.7rem;
                  svg {
                    //color: $semantic-info;
                    transform: rotate(317deg);
                    height: 1.5rem;
                    width: 1.5rem;
                    stroke-width: 0.3;
                  }
                }
                .more-info {
                  // background-color: red;
                  .more-info-station {
                    background-color: #1e3249;
                    font-size: 0.9rem;
                    margin-left: 5px;
                    padding: 0.1rem 0.5rem;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
        .station-access-meta-info {
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          flex: 1;
          place-content: flex-end;
          gap: 10px;
          .copy-clipboard__block {
            .copy-btn {
              border: none;
              color: white;
              border-radius: 10px;
              padding: 10px;
              background: none;
              .copy--icon {
                height: 1.5rem;
                width: 1.5rem;
              }
              .copy--icon:hover {
                height: 1.5rem;
                width: 1.5rem;
                color: rgba(255, 255, 255, 0.581);
              }
            }
          }
          .qr-code--btn {
            padding: 5px;

            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            cursor: pointer;
          }
          .qr-code--btn:hover {
            color: rgba(255, 255, 255, 0.581);
          }
        }
      }
      .station-access-list--button {
        margin-left: auto;
        .station-access-action--btn {
          width: 7.5rem;
          border-radius: 5px;
          border: none;
          font-weight: 400;
          font-size: 0.85rem;
          padding: 0.7rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .loader--block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 1024px) {
    .station-access-details--block {
      display: flex;
      flex-direction: column;
      .plug-info--block {
        margin-top: 1rem;
      }
    }
  }
}
