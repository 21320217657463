.tenantAdminList-page {
  .tenantAdminList-page-wrapper {
    .tenantAdminList-search-box {
      display: flex;
      align-items: center;
      .tenantAdminList-search-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .tenantAdminList-search-box-Btn {
          padding: 0.8rem 1rem;
        }
      }
    }
    .tenantAdminList-table {
      .tenantAdminList-icon-list {
        .tenant-lock,
        .item {
          display: flex;
          gap: 10px;
          place-content: flex-start;
        }
      }
      .tenantAdminList-table-editIcon {
        font-size: 1.2rem;
        color: #788a9b;
        cursor: pointer;
      }
      .tenantAdminList-lock-unlock-icon {
        color: #788a9b;
        cursor: pointer;
      }
      .tenantAdminList-table-deleteIcon {
        color: #788a9b;
        cursor: pointer;
      }
      .tenantAdminList-table-pinIcon {
        color: #788a9b;
      }
      .tenantAdminList-nameList {
        display: flex;
        align-items: center;
        .tenantAdminList-avatarList {
          height: 1.7rem;
          width: 1.7rem;
        }
        .user-info--name {
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .company-logo {
        width: 100%;
        height: auto;
        margin-right: 0px;
        img {
          object-fit: contain;
          border-radius: 0px;
          object-position: left;
          position: relative;
          height: 50px;
          width: 150px;
        }
      }
      .tenant-mode {
        text-transform: uppercase;
      }
      .tenant-action-btn {
        gap: 10px;
        #tenant-admin-login
        {
          width: 100px;
        }
        .has-dropdown {
          padding: 0.6rem 0.3rem;
        }
      }
      td {
        border-bottom: 0px;
      }
    }
  }
}
