.advance-report-form-page-main {
    .advance-report-form-card {
      .card-body {
        padding: 2rem 2.5rem;
      }
  
      .advance-report-form__block {
        padding-bottom: 2rem;
  
        .advance-report-form__inner {
          padding: 1rem 0;
        }
  
        .advance-report-form-save--btn--block {
          .advance-report-form-save-btn {
            padding: 1rem 1.5rem;
          }
        }
      }
    }
  }
  
  .advance-report-form-body__block {
    padding: 1rem 2rem;
  
    .advance-report-form--block {
      .row {
        position: relative;
        margin-bottom: 1em;
        .col.btn_del {
          position: absolute;
          text-align: right;
          .primary-button {
            padding: 0px;
          }
        }
      }
      .advance-report-add-btn {
        button {
          padding: 0px;
        }
      }
      .advance-report-form-btn__block {
        margin-top: 1rem;
  
        .advance-report-form-btn {
          padding: 1rem 1.5rem;
          font-weight: 500;
        }
        .margin_top {
          margin-top: 12px;
        }
      }
      .margin_top {
        margin-top: 12px;
        font-size: small;
      }
      .font-color {
        margin-bottom: 10px;
        color: $special-admin;
      }
      .time_sec {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        .to_sec {
          padding: 8px;
          margin: auto;
        }
        .custome_time {
          display: flex;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background-color: transparent !important;
          flex-wrap: wrap;
          .ct_selecter {
            padding: 0.5em;
            width: 100% !important;
            height: 100% !important;
            border-radius: 10px !important;
            color: $input-value;
            outline: none;
            border: 1px solid $input-border !important;
            background-color: transparent !important;
          }
        }
      }
      .advance-report-input-box__block {
        .advance--btn {
          width: 20rem;
          text-align: left;
          padding: 0.7rem;
          &:focus {
            box-shadow: none;
          }
        }
        .cancel--btn {
          background-color: transparent;
          border-color: $special-admin;
          text-align: center;
          color: $brand-accent;
          &:hover{
            background-color: $gray-700;
          }
        }
        .remove--btn {
          text-align: center;
        }
      }
    }
  }
  
  .advance-report-form-text-align {
    text-align: center;
    font-weight: bold;
    font-size: larger;
  }
  .advance-close-btn {
    width: 40px;
    padding: 0.1rem;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 40px;
    text-align: center;
    &:hover {
      background-color: $light-table-header;
    }
  }
  
  .field--btn-prime {
    color: $semantic-info !important;
  }
  
  .advance-report-list-btn {
    height: 65%;
    background-color: $gray-600;
    border: $gray-600;
    &:hover {
      background-color: $brand-secondary;
      border: $gray-600;
    }
    &:focus {
      background-color: $brand-secondary;
    }
  }
  
  .advance-report-btns-sec {
    display: flex;
    justify-content: space-between;
    place-items: center;
    .advance-report-submit-btn {
      button {
        padding: 0.8rem 1rem !important;
      }
    }
  }
  @media (max-width: 768px) {
    .advance-report-form-body__block {
      padding: 1em;
    }
  }