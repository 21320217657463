.vehicle-management-page_main {
  .vehicle-management-card-block {
    margin-bottom: 0.5rem;

    .vehicle-card-block {
      cursor: pointer;

      &.active {
        border-bottom: 2px solid $brand-accent;
      }
    }
  }

  .vehicle-list-main-block {
    .vehicle-list-block {
      .vehicle-search-box {
        margin-bottom: 1.5rem;
        .row {
          place-items: center;
          .form-input-group {
            margin-bottom: 0px;
          }
          .add-vehicle-btn {
            padding: 0.8rem 1rem;
          }
        }
      }

      .vehicle-list-table {
        .table-responsive {
          .record-list-table {
            .vehicle-name {
              .car-icon {
                margin-right: 0.9rem;

                svg {
                  height: 1.2rem;
                  width: 1.2rem;
                }
              }
            }

            .vehicle-moreInfo {
              color: #6f9de3;
            }
          }
        }
        .vehicle-list-icon-list {
          .vehicle-lock,
          .item {
            display: flex;
            gap: 10px;
            place-content: flex-start;
          }
        }
        .vehicle-list-table-editIcon {
          font-size: 1.2rem;
          color: #788a9b;
          cursor: pointer;
        }
        .vehicle-list-lock-unlock-icon {
          color: #788a9b;
          cursor: pointer;
        }
        .vehicle-list-table-deleteIcon {
          color: #788a9b;
          cursor: pointer;
        }
      }
    }
  }

  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.vehicle-card {
  .card-body {
    padding: 1rem;

    .vehicle-detail-box {
      display: flex;
      margin-top: -19px;

      .vehicle-icon {
        position: relative;
        margin-right: 1rem;
        padding: 0.3rem;

        .car-icon {
          svg {
            height: 2.5rem;
            width: 2.5rem;
          }
        }

        .vehicle-status-icon {
          svg {
            position: absolute;
            bottom: 0rem;
            right: -0.5rem;
            height: 1rem;
            width: 1rem;
          }
        }
      }

      .vehicle__count-info {
        .total-vehicle {
          font-size: 1.25rem;
          font-weight: 600;
        }

        .info-text {
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
  }
}

.Vehicle-model-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.Vehicle-model-icon .Vehicle-model-icon-photo {
  font-size: 40px;
  margin-right: 10px;
}

.chargerStateList-table-editIcon {
  margin-right: 1.5rem;
  font-size: 1.2rem;
  color: $semantic-info;
  cursor: pointer;
}

.chargerStateList-table-deleteIcon {
  font-size: 1.2rem;
  color: $brand-accent;
  cursor: pointer;
}
