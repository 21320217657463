.smc-jv {
  padding: 30px;
  font-weight: 400;

  .smc-jv-ivoice-table {
    width: 100%;
    tr {
      th,
      td {
        border: 2px solid $light-table-header;
      }
      .border_none {
        border: none !important;
      }
    }
  }
  .smc-jv-no {
    margin-right: 40px;
    border: 2px solid;
    font-weight: 900;
    font-size: 50px;
    text-align: center;
  }
  .df {
    display: flex;
    justify-content: space-between;
  }
  .text-align-center {
    text-align: center;
  }
  .border_bottom,
  .border_top {
    border-bottom: 2px solid $light-table-header !important;
  }
  .mr-tb {
    margin: 4px 0;
  }
  .border_custom {
    border-left: none !important;
    border-bottom: none !important;
    border-right: none !important;
  }
  .row_formeting {
    padding: 15px !important;
    text-align: center;
  }
}
