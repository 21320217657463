.data-report--main {
  .data-report--block {
    // .main-card-container {
    //   max-width: 99rem;
    .data-report__inner {
      .data-report__box {
        margin-bottom: 1rem;
        .data-report__row {
          .search-box-option {
            display: flex;
            gap: 5px;
            .select-input-form-group {
              .react-select__control {
                border-radius: 15px !important;
                padding-left: 5px;
                padding-right: 5px;
              }
            }
          }
          .data-report-search-box {
            padding-top: 0.9rem;
            padding-left: 2rem;
            .report-search-icon {
              cursor: pointer;
            }
          }
          .log-filter-block {
            display: flex;
            padding: 0.75rem;
            .log-filter-icon {
              display: flex;
              align-items: center;
              .log-filter-label {
                padding-left: 0.5rem;
                font-size: 0.875rem;
                font-weight: 600;
              }
            }
            .log-arrow__icon {
              color: $brand-accent;
              margin-left: auto;
            }
          }
          .log-filter-dropdown {
            min-width: 12rem;
            margin-right: 1rem;
            @media screen and (max-width: 1024px) {
              min-width: 10rem;
            }
            @media screen and (max-width: 768px) {
              min-width: 8rem;
            }
          }
          .report-search-button {
            border: none;
            cursor: pointer;
            border-radius: 5px;
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
          }
          .report-search-excel-block {
            padding-left: 0.5rem;
            // padding-top: 0.5rem;
          }
          .report-search-box {
            // padding-top: 0.5rem !important;
            .advance-filter-btn {
              .primary-button {
                padding: 0.2rem 1rem 0.2rem 1rem;
              }
            }
          }
          .excel-icon--block {
            padding-top: 0.6rem;
            .report-excel-icon {
              cursor: pointer;
              .download-table-xls-button {
                color: $special-excel;
                background: transparent;
                border: none;
                svg {
                  height: 2rem;
                }
              }
            }
          }
          // .data-report__table-download-icon {
          //   padding: 0.7rem;
          //   text-align: center;
          //   background: #182532;
          //   cursor: pointer;
          //   svg {
          //     font-size: 1.3rem;
          //     color: #be210b;
          //   }
          // }
          // .underline {
          //   border-bottom: 0.2rem solid #3d4e5f;
          //   width: 13rem;
          //   margin-left: 1.2rem;
          //   padding-top: 1rem;
          // }
          // .apply-content {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   padding: 0.6rem;
          //   .apply-label {
          //     color: $brand-accent;
          //     font-size: 0.875rem;
          //     font-weight: 700;
          //   }
          // }
        }
        .react-select .react-select__control {
          border: none;
          box-shadow: none !important;
          min-height: 32px;
          cursor: pointer;
          background: $gray-600;
          padding: 0.1rem;
          border-radius: 0 !important;
        }
      }
      .data-report__table {
        .data-report_inner {
          .json-data__block {
            white-space: unset !important;
            .json--data {
              max-width: 550px;
            }
          }
        }
      }
    }
    // }
    .loader--block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/* Jov voucher styling */

.job-voucher-filter-block {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .job-voucher-filter-sec {
    width: 100%;
    padding: 0 10px;
  }

  .job-voucher-arrow__icon {
    padding: 9px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .report-search-button svg {
    display: block;
  }
  .report-search-button span {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .data-report__row.row {
    display: flex;
    gap: 10px;
    place-content: start;
    place-items: baseline;
  }
  .data-report__row.row [class*='col-'] {
    padding-left: 0px;
    padding-right: 0px;
    width: auto;
  }
  .dataPicker-input-group .input-field {
    max-width: min-content;
  }
  .data-report-search-box {
    padding-left: 0px !important;
  }
  .report-search-button svg {
    display: block;
    height: 1.35rem;
  }
  .report-search-button span {
    display: none;
  }
  .data-report__row.row .dataTimePicker-floating-label-input-group .input-field {
    width: 13rem;
  }
  .data-report__row.row .dataTimePicker-floating-label-input-group {
    gap: 0.5rem;
  }
  .report-search-excel-block {
    padding-left: 0rem !important;
    // padding-top: 0.5rem;
  }
}
@media (min-width: 1280px) and (max-width: 1320px) {
  .data-report__row.row {
    gap: 0px;
  }
  .dataPicker-input-group .input-field {
    max-width: min-content;
  }
  .data-report-search-box {
    padding-left: 0px !important;
  }
  .report-search-button svg {
    display: none;
  }
  .report-search-button span {
    display: block;
  }
}
@media (min-width: 1321px) {
  .report-search-button svg {
    display: none;
  }
  .report-search-button span {
    display: block;
  }
}

/* No Load styling */

.No-Load-block {
  display: flex;
  .download-sample--block {
    display: flex;
    margin: 1rem;
    .download-sample-file {
      font-size: 0.9rem;
      color: #58d37d;
      cursor: pointer;
      font-weight: 500;
    }
  }
}
.file-upload-popup {
  display: flex;
  flex-direction: column;
  background-color: $brand-primary;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.file-drop-area {
  border: 2px dashed #ccc;
  padding: 150px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.file-drop-area p {
  margin: 0;
}

.small-text {
  font-size: 12px;
  color: #666;
}

.cancel-button {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #ddd;
}

/* Button and icon styling */
.reload-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Class to apply spinning animation */
.spin {
  animation: spin 1s linear infinite;
}

/* Define the spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// MultiSelectCheckBox Css
// .css-1r4vtzz {
//   background: #182532 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0!important;
//   // padding: 1rem 6rem 1rem 1rem !important;
//   // display: flex !important;
//   .css-1v99tuv {
//     overflow: visible;
//     padding: 1rem 5.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2{
//     margin: 0rem 0.125rem !important;
//   }
// }

// .css-1pcexqc-container {
//   color: rgb(250, 250, 251) !important;
//   background-color: #223343 !important;
//   .css-1jllj6i-control {
//     background: #223343 !important;
//     border-color: #182532 !important ;
//   }
// }

// .css-11unzgr {
//   overflow-y: auto;
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: rgba(195, 194, 197, 0.4);
//   }

//   .css-1gl4k7y {
//     color: rgb(250, 250, 251) !important;
//     font-weight: normal !important;
//     font-size: 0.9rem !important;
//     padding: 0.5rem 4.9rem;
//   }
// }
// .css-48ayfv {
//   background-color: #182531 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem !important;
//   // padding: 0.75rem 3rem !important;
//   .css-1v99tuv{
//     // overflow: visible;
//     padding:1rem 5.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2{
//     margin: 0rem 0.125rem !important;
//   }
// }
// .css-1qprcsu-option {
//   background-color: #223343 !important;
//   color: rgb(250, 250, 251) !important;
//   font-weight: normal !important;
//   font-size: 0.9rem !important;
// }
// .css-1vr111p-option {
//   font-size: 0.9rem !important ;
// }
// .css-1vr111p-option:hover {
//   background-color: #223343 !important ;
// }
// .css-1gpjby2 {
//   svg {
//     stroke: currentcolor;
//     fill: currentcolor;
//     // margin-left: 3.5rem;
//     width: 12px;
//     height: 12px;
//     padding: 0 1rem 0 0.5rem;
//     stroke-width: 0;
//     color: rgb(244, 63, 63);
//     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
//     background-size: contain;
//     padding: 0rem 1.5rem 0rem 1rem!important
//   }
// }
// .css-48ayfv {
//   .css-1v99tuv {
//     overflow: visible;
//     padding: 1rem 5.5rem 1rem 1rem;
//   }
// }
// }
// //booking-history
// .booking-history-list--main {
//   .booking-history-list-box {
//     margin-bottom: 1rem;
//     .booking-history-row {
//       margin: 0;
//       .history-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .booking-history-date-picker {
//         margin-bottom: 0.5rem;
//       }
//       .booking-history-select--box {
//         cursor: pointer;
//         .booking-history-label {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 0.813rem;
//           color: #ffffff;
//           line-height: 1rem;
//           .booking-history-select-input {
//             > div {
//               background: #182532;
//               border: 1px solid #1b2a38;
//               box-sizing: border-box;
//               border-radius: 0 !important;
//               padding: 0.2rem;
//             }
//             .css-qc6sy-singleValue,
//             .css-14el2xx-placeholder {
//               margin-left: 3rem;
//               font-style: normal;
//               font-weight: normal;
//               font-size: 0.813rem;
//               line-height: 1rem;
//               color: rgba(255, 255, 255, 0.5);
//             }
//             .css-6j8wv5-Input > button,
//             input,
//             optgroup,
//             select,
//             textarea {
//               margin-left: 3rem !important;
//               font-style: normal !important;
//               font-weight: normal !important;
//               font-size: 0.813rem !important;
//               line-height: 1rem !important;
//               color: rgba(255, 255, 255, 0.5) !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// //charging-history
// .charging-history-list--main {
//   .charging-history-list-box {
//     margin-bottom: 1rem;
//     .charging-row {
//       margin: 0;
//       .charging-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .charging-history-date-picker {
//         margin-bottom: 0.5rem;
//       }
//     }
//   }
// }

// //station-charging-summary
// .station-charging-summary-list--main {
//   .station-charging-summary-list-box {
//     margin-bottom: 1rem;
//     .station-charging-summary-row {
//       margin: 0;
//       .station-charging-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .station-charging-summary-date-picker {
//         margin-bottom: 0.5rem;
//       }
//       .station-charging-summary-select--box {
//         cursor: pointer;
//         .station-charging-summary-label {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 0.813rem;
//           color: #ffffff;
//           line-height: 1rem;
//           .station-charging-summary-select-input {
//             > div {
//               background: #182532;
//               border: 1px solid #1b2a38;
//               box-sizing: border-box;
//               border-radius: 0 !important;
//               padding: 0.2rem;
//             }
//             .css-qc6sy-singleValue,
//             .css-14el2xx-placeholder {
//               margin-left: 3rem;
//               font-style: normal;
//               font-weight: normal;
//               font-size: 0.813rem;
//               line-height: 1rem;
//               color: rgba(255, 255, 255, 0.5);
//             }
//             .css-6j8wv5-Input > button,
//             input,
//             optgroup,
//             select,
//             textarea {
//               margin-left: 3rem !important;
//               font-style: normal !important;
//               font-weight: normal !important;
//               font-size: 0.813rem !important;
//               line-height: 1rem !important;
//               color: rgba(255, 255, 255, 0.5) !important;
//             }
//           }
//         }
//       }
//       .station-charging-summary-download-icon {
//         padding: 0.7rem;
//         text-align: center;
//         background: #182532;
//         cursor: pointer;
//         svg {
//           font-size: 1.3rem;
//           color: #be210b;
//         }
//       }
//     }
//   }
// }

// .css-1r4vtzz {
//   background: #182532 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem 2rem 0rem 0rem !important;
//   // padding: 1rem 6rem 1rem 1rem !important;
//   // display: flex !important;
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2 {
//     padding: 1rem 1.5rem 1rem 1rem !important;
//     // margin: 0rem 3rem;
//   }
// }

// .css-1pcexqc-container {
//   color: rgb(250, 250, 251) !important;
//   background-color: #223343 !important;
//   .css-1jllj6i-control {
//     background: #223343 !important;
//     border-color: #182532 !important ;
//   }
// }

// .css-11unzgr {
//   overflow-y: auto;
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: rgba(195, 194, 197, 0.4);
//   }

//   .css-1gl4k7y {
//     color: rgb(250, 250, 251) !important;
//     font-weight: normal !important;
//     font-size: 0.9rem !important;
//     padding: 0.5rem 4.9rem;
//   }
// }
// .css-48ayfv {
//   background-color: #182531 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem !important;
//   // padding: 0.75rem 3rem !important;
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem;
//   }
//   .css-1gpjby2 {
//     padding: 1rem 1.5rem 1rem 1rem !important;
//   }
// }
// .css-1qprcsu-option {
//   background-color: #223343 !important;
//   color: rgb(250, 250, 251) !important;
//   font-weight: normal !important;
//   font-size: 0.9rem !important;
// }
// .css-1vr111p-option {
//   font-size: 0.9rem !important ;
// }
// .css-1vr111p-option:hover {
//   background-color: #223343 !important ;
// }
// .css-1gpjby2 {
//   svg {
//     stroke: currentcolor;
//     fill: currentcolor;
//     // margin-left: 3.5rem;
//     width: 12px;
//     height: 12px;
//     padding: 0 1rem 0 0.5rem;
//     stroke-width: 0;
//     color: rgb(244, 63, 63);
//     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
//     background-size: contain;
//     // padding: 0rem 1.5rem !important
//   }
// }
// .css-48ayfv {
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem;
//   }
// }
// // }
