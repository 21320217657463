.adminmain {
  background-color: $brand-primary;
  padding: 2rem;
  border-radius: 10px;
  margin: 1rem 0rem;

  h4 {
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: $input-value;
    padding: 0.5rem 1rem 1rem;
    position: relative;
    width: auto;
    border-bottom: 1px solid $activity-icon-block-border;
  }

  button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: $brand-accent;
    cursor: pointer;
    border-radius: 15px;
    color: $input-value;
    margin-top: 10px;
    margin-left: 5px;

    &:hover {
      background-color: $button-primary-hover;
    }
  }

  input {
    margin: 5px;
    padding: 0.5rem 1rem;
    width: 300px;
    background-color: $gray-700;
    border: none;
    border-radius: 5px;
    outline: none;
    color: $input-value;
  }
}

.radioModule {
  margin: 5px;
  /* background-color: red; */
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  input {
    width: auto;
    margin-right: 10px;
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
    border: 2px solid $special-admin;
    box-sizing: border-box;
    border-radius: 50%;
    padding: inherit;
  }

  label {
    width: auto;
    margin-right: 20px;
    //padding: 10px;
    border-radius: 5px;
    //background-color: $gray-700;
    cursor: pointer;
  }
}

.selectdate {
  background-color: $gray-700;
}

.dateselect2 {
  width: auto;
  display: flex;
  // background-color: red;
  align-items: center;

  p {
    margin-top: 10px;
    margin-right: 5px;
  }
}

#clear {
  background-color: $brand-primary;
}

.import-admin-block {
  display: flex;
  .download-sample-block {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    .download-sample--file {
      font-size: 0.9rem;
      color: #58d37d;
      cursor: pointer;
      font-weight: 500;
    }
  }
}