.charger-activity-log-page__main {
  .activity-header--block {
    .session-heading-right-content {
      display: flex;
      .filter-block {
        display: flex;
        padding: 0.75rem;
        .filter-icon {
          display: flex;
          align-items: center;
          .filter-label {
            padding-left: 0.5rem;
            font-size: 0.875rem;
            font-weight: 600;
          }
        }
        .arrow__icon {
          color: $brand-accent;
          margin-left: auto;
        }
      }
      .filter-dropdown {
        min-width: 12rem;
        margin-right: 1rem;
        @media screen and (max-width: 1024px) {
          min-width: 10rem;
        }
        @media screen and (max-width: 768px) {
          min-width: 8rem;
        }
      }
      .item {
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 0.941rem;
      }
      .underline {
        border-bottom: 0.2rem solid #3d4e5f;
        width: 13rem;
        margin-left: 1.2rem;
        padding-top: 1rem;
      }
      .apply-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        .apply-label {
          color: $brand-accent;
          font-size: 0.875rem;
          font-weight: 700;
        }
      }
    }
  }
  .activity-content__block-main {
    padding: 0.5rem 0;
    .activity-text-color {
      color: $input-value;
    }
  }
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// activityLog Modal
.activityLog__main {
  .activityLog__block {
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $tab-active-color;
    .activityLog-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $light-table-header;
      }
    }
  }
  .json-table {
    .json-to-table tr {
      background: unset;
      border: 1px solid #eee;
    }
  }
}
