.excel-icon--block {
  padding-top: 0.6rem;
  .report-excel-icon {
    text-align: center;
  }
}

.status-blocks {
  .status-block:nth-child(odd) {
    svg {
      color: $semantic-info; /* Blue color */
    }
    .breating-area {
      background: $status-breathing-blue; /* Light blue background */
    }
  }

  .status-block:nth-child(even) {
    svg {
      color: $brand-accent; /* Red color */
    }
    .breating-area {
      background: $status-breathing-red; /* Light red background */
    }
  }
}
