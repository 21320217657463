.data-report--main {
  .data-report--block{
  // .main-card-container {
  //   max-width: 99rem;

    .data-report__inner {
      .data-report__box {
        margin-bottom: 1rem;

        .data-report__row {
          .data-report-search-box {
            padding-top: 0.9rem;
            padding-left: 2rem;

            .report-search-icon {
              cursor: pointer;
            }
          }

          .log-filter-block {
            display: flex;
            padding: 0.75rem;

            .log-filter-icon {
              display: flex;
              align-items: center;

              .log-filter-label {
                padding-left: 0.5rem;
                font-size: 0.875rem;
                font-weight: 600;
              }
            }

            .log-arrow__icon {
              color: $brand-accent;
              margin-left: auto;
            }
          }

          .log-filter-dropdown {
            min-width: 12rem;
            margin-right: 1rem;

            @media screen and (max-width: 1024px) {
              min-width: 10rem;
            }

            @media screen and (max-width: 768px) {
              min-width: 8rem;
            }
          }

          .report-search-button {
            border: none;
            cursor: pointer;
            border-radius: 15px;
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
          }

          .report-search-excel-block {
            padding-left: 0.5rem;
            // padding-top: 0.5rem;
          }

          .report-search-box {
            // padding-top: 0.5rem !important;
            .advance-filter-btn {
              .primary-button {
                padding: 0.2rem 1rem 0.2rem 1rem;
              }
            }
          }

          .excel-icon--block {
            padding-top: 0rem;

            .report-excel-icon {
              cursor: pointer;
              background: $gray-600;
              border: 3px solid $gray-600;
              box-sizing: border-box;
              border-radius: 15px;

              svg {
                font-size: 1.5rem;
                margin: 8px;
              }
            }
          }

          .advance-filter-icon {
            cursor: pointer;
            background: $gray-600;
            border: 3px solid $gray-600;
            box-sizing: border-box;
            border-radius: 15px;

            svg {
              font-size: 1.5rem;
              margin: 8px;
            }
          }

          // .data-report__table-download-icon {
          //   padding: 0.7rem;
          //   text-align: center;
          //   background: #182532;
          //   cursor: pointer;
          //   svg {
          //     font-size: 1.3rem;
          //     color: #be210b;
          //   }
          // }
          // .underline {
          //   border-bottom: 0.2rem solid #3d4e5f;
          //   width: 13rem;
          //   margin-left: 1.2rem;
          //   padding-top: 1rem;
          // }
          // .apply-content {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   padding: 0.6rem;
          //   .apply-label {
          //     color: $brand-accent;
          //     font-size: 0.875rem;
          //     font-weight: 700;
          //   }
          // }
        }

        .react-select .react-select__control {
          border: none;
          box-shadow: none !important;
          min-height: 32px;
          cursor: pointer;
          background: $gray-600;
          padding: 0.1rem;
          border-radius: 0 !important;
        }
      }

      .data-report__table {
        .record-list-table {
          .station-link,
          .charger-link {
            cursor: pointer;
            text-align: left;
            text-wrap: wrap;
          }
          .station-link:hover,
          .charger-link:hover {
            text-decoration: underline;
          }

          .dropdown-link {
            cursor: pointer;
          }

          .dropdown-link svg:hover {
            color: $brand-accent;
          }
        }

        .record-list-table.chargerstatus-list-table {
          .station-link,
          .charger-link {
            cursor: pointer;
            svg {
              margin-right: 10px;
              height: 25px;
            }
          }

          .station-link {
            flex: 25%;
            display: flex;

            svg {
              flex: 30px;
            }

            span {
              white-space: pre-line;
              flex: 100%;
              width: 270px;
            }
          }

          .station-link:hover,
          .charger-link:hover {
            text-decoration: underline;
          }

          .dropdown-link {
            cursor: pointer;
            text-align: right;

            svg {
              border-radius: 10px;
              background: $tab-active-color;
              padding: 10px;
              width: 36px;
              height: 36px;
            }
          }

          .dropdown-link svg:hover {
            color: $brand-accent;
          }

          thead {
            tr {
              border-bottom: 0px;
              display: flex;
              flex-direction: row;

              th {
                border-color: $tab-active-color;
                flex: 1;
                border-radius: 0px;
                &:hover {
                  border-radius: 10px;
                }
              }

              th:first-child {
                flex: 24.5%;
              }

              th:nth-child(3) {
                flex:7%;
              }

              @media only screen and (max-width: 1700px) {
                th:nth-child(3) {
                  flex:0%;
                }

                th {
                  flex: 1.1;
                }

              }

            }
          }

          tbody {
            tr {
              display: flex;
              flex-direction: row;
              border-bottom: 0px;

              td {
                background-color: transparent;
                border-bottom: 0px;
                flex: 1;
              }

              // .con {
              //   display: contents;
              // }
            }

            .sub-table {
              margin: 10px;

              tr {
                margin: 0px;
                border-radius: 15px 15px 0px 0px;
                border: 0px;
                display: flex;
              }
            }
          }

          .charger-status-tr {
            display: flex;
            flex-direction: column;
            border-radius: 20.453px;
            border: 1.023px solid $tab-active-color;
            margin: 10px 0px;
          }

          .charger-status-tr tr td:nth-child(3),
          .charger-status-tr tr th:nth-child(3) {
            flex: 8% !important;
          }

          .status_charger {
            border-radius: 30.68px;
            padding: 5px 20px;
            text-align: center;
          }

          .status-Available {
            background: #0f9b72;

            svg {
              display: none;
            }
          }

          .status-PowerLoss {
            background: #be210b;

            svg {
              display: none;
            }
          }

          .status-Unavailable {
            background: #ff8b00;

            svg {
              display: none;
            }
          }

          .status-Charging,
          .status-In,
          .status_charger.Use {
            background: $semantic-info;
            box-shadow: 0px 0px 4px 4px $status-breathing-blue;
            position: relative;
            flex-wrap: wrap;
            display: flex;
            justify-content: space-around;
            animation-name: spreadanimation;
            animation-duration: 2s;
            animation-iteration-count: infinite;

            svg {
              display: block;
              width: 20px;
              height: 20px;
              // position: absolute;
              left: 1em;
            }

            @media only screen and (max-width: 1500px) {
              svg {
                display: none;
                width: 20px;
                height: 20px;
                // position: absolute;
                left: 1em;
              }
            }

            .border {
              stroke: $status_charger-yellow;
              stroke-width: 1;
              fill: none;
              animation: borderAnimation 3s infinite;
            }

            .fill {
              fill: $status_charger-yellow;
              opacity: 0;
              animation: fillAnimation 3s infinite;
            }

            @keyframes spreadanimation {
              0% {
                box-shadow: 0px 0px 4px 4px $status-breathing-blue;
              }

              20% {
                box-shadow: 0px 0px 8px 10px $status-breathing-blue;
              }

              40% {
                box-shadow: 0px 0px 8px 10px $status-breathing-blue;
              }

              100% {
                box-shadow: 0px 0px 4px 4px $status-breathing-blue;
              }
            }

            @keyframes borderAnimation {
              0% {
                stroke-dasharray: 0, 100;
              }

              50% {
                stroke-dasharray: 100, 0;
              }

              100% {
                stroke-dasharray: 0, 100;
              }
            }

            @keyframes fillAnimation {
              0% {
                opacity: 0;
              }

              50% {
                opacity: 1;
              }

              100% {
                opacity: 0;
              }
            }
          }
        }

        .data-report_inner {
          .json-data__block {
            white-space: unset !important;

            .json--data {
              max-width: 550px;
            }
          }
        }

        .connector-charging {
          //background: #3b7ddd;
          color: $semantic-info;
          border-color: $semantic-info;
        }

        .connector-unavailable {
          color: $input-value;
          border-color: $input-value;
          color: $input-value;
          border-color: $input-value;
        }

        .connector-wrapper {
          display: flex;
          flex-wrap: wrap;
          max-width: 200px;

          .connector-circle {
            color: $input-value;
            border-radius: 50%;
            padding: 0px 10px 0px 0px;
            font-size: 12px;
            margin-right: 10px;
            font-weight: 500;
            margin: 5px;
          }

          .available {
            svg {
              color: #0f9b72;
              //background-color: #109b7147;
              transform: rotate(317deg);
            }
          }

          .charging {
            svg {
              color: $semantic-info;
              //background-color: #3b7cdd4b;
              transform: rotate(317deg);
            }
          }
          
          .preparing {
            svg {
              color: $input-value;
              //background-color: #3b7cdd4b;
              transform: rotate(317deg);
            }
          }

          .unavailable {
            svg {
              color: #ff8b00;
              //background-color: rgba(190, 32, 11, 0.308);
              transform: rotate(317deg);
            }

            .Faulted
            {
              svg {
                color: #be210b;
                //background-color: #3b7cdd4b;
                transform: rotate(317deg);
              }
            }

          }

          .PowerLoss {
            svg {
              color: #be210b;
              //background-color: #109b7147;
              transform: rotate(317deg);
            }
          }
          .more-than-one-letter {
            border-radius: 8px;
            margin: 5px;
            font-size: 12px;
          }

          .more-than-one-letter {
            border-radius: 8px;
          }

          // .more-than-one-letter {
          //   border-radius: 8px;
          //   margin: 5px;
          //   font-size: 15px;
          // }
          // .more-than-one-letter {
          //   border-radius: 8px;
          // }
        }

        .sub-table {
          display: contents;
          transition: all 0.3s ease-in-out;

          .sub-table-head-row:first-child {
            th:first-child {
              border-top-left-radius: 8px;
              transition: all 0.3s ease-in-out;
            }

            th:last-child {
              border-top-right-radius: 8px;
              transition: all 0.3s ease-in-out;
            }
          }

          .sub-table-body-row:last-child {
            td:first-child {
              border-bottom-left-radius: 8px !important;
            }

            td:last-child {
              border-bottom-right-radius: 8px !important;
            }
          }

          .sub-table-head-row,
          .sub-table-body-row {
            th,
            td {
              border-style: none solid solid none;
              transition: all 0.3s ease-in-out;
              flex: 1;
            }
          }
        }

        .sub-table-head-row,
        .sub-table-body-row {
          border-bottom: 1px solid $table-sub-row-bg;
        }

        .sub-table-head-row th {
          padding: 0.938rem 1.563rem;
          color: $table-header;
          font-size: 0.875rem;
          font-weight: normal;
        }

        .sub-table-body-row .available {
          svg {
            color: #0f9b72;
            transform: rotate(317deg);
            margin-right: 10px;
          }
        }

        .sub-table-body-row .charging {
          svg {
            color: $semantic-info;
            transform: rotate(317deg);
            margin-right: 10px;
          }
        }

        .sub-table-body-row .preparing {
          svg {
            color: $input-value;
            transform: rotate(317deg);
            margin-right: 10px;
          }
        }

        .sub-table-body-row .unavailable {
          svg {
            color: #ff8b00;
            transform: rotate(317deg);
            margin-right: 10px;
          }
        }
        
        .sub-table-body-row .PowerLoss {
          svg {
            color: #be210b;
            transform: rotate(317deg);
            margin-right: 10px;
          }
        }
      }
    }
  }

  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search_box_div {
    @media (min-width: 768px) and (max-width: 1200px) {
      flex: 1;
    }
  }

  .search_btn_download {
    display: flex;
    flex-direction: row;
    place-items: baseline;

    @media (min-width: 768px) and (max-width: 1200px) {
      gap: 15px;
    }

    .report-search-box {
      flex: 1;

      button {
        width: 100%;
      }
    }

    .excel-icon--block {
      flex: 1;
      display: flex;
      place-content: center;
    }
  // }
}
}
.wise_status {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 1em;
  margin-left: 1em;
}
// MultiSelectCheckBox Css
// .css-1r4vtzz {
//   background: #182532 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0!important;
//   // padding: 1rem 6rem 1rem 1rem !important;
//   // display: flex !important;
//   .css-1v99tuv {
//     overflow: visible;
//     padding: 1rem 5.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2{
//     margin: 0rem 0.125rem !important;
//   }
// }

// .css-1pcexqc-container {
//   color: rgb(250, 250, 251) !important;
//   background-color: #223343 !important;
//   .css-1jllj6i-control {
//     background: #223343 !important;
//     border-color: #182532 !important ;
//   }
// }

// .css-11unzgr {
//   overflow-y: auto;
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: rgba(195, 194, 197, 0.4);
//   }

//   .css-1gl4k7y {
//     color: rgb(250, 250, 251) !important;
//     font-weight: normal !important;
//     font-size: 0.9rem !important;
//     padding: 0.5rem 4.9rem;
//   }
// }
// .css-48ayfv {
//   background-color: #182531 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem !important;
//   // padding: 0.75rem 3rem !important;
//   .css-1v99tuv{
//     // overflow: visible;
//     padding:1rem 5.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2{
//     margin: 0rem 0.125rem !important;
//   }
// }
// .css-1qprcsu-option {
//   background-color: #223343 !important;
//   color: rgb(250, 250, 251) !important;
//   font-weight: normal !important;
//   font-size: 0.9rem !important;
// }
// .css-1vr111p-option {
//   font-size: 0.9rem !important ;
// }
// .css-1vr111p-option:hover {
//   background-color: #223343 !important ;
// }
// .css-1gpjby2 {
//   svg {
//     stroke: currentcolor;
//     fill: currentcolor;
//     // margin-left: 3.5rem;
//     width: 12px;
//     height: 12px;
//     padding: 0 1rem 0 0.5rem;
//     stroke-width: 0;
//     color: rgb(244, 63, 63);
//     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
//     background-size: contain;
//     padding: 0rem 1.5rem 0rem 1rem!important
//   }
// }
// .css-48ayfv {
//   .css-1v99tuv {
//     overflow: visible;
//     padding: 1rem 5.5rem 1rem 1rem;
//   }
// }
// }
// //booking-history
// .booking-history-list--main {
//   .booking-history-list-box {
//     margin-bottom: 1rem;
//     .booking-history-row {
//       margin: 0;
//       .history-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .booking-history-date-picker {
//         margin-bottom: 0.5rem;
//       }
//       .booking-history-select--box {
//         cursor: pointer;
//         .booking-history-label {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 0.813rem;
//           color: #ffffff;
//           line-height: 1rem;
//           .booking-history-select-input {
//             > div {
//               background: #182532;
//               border: 1px solid #1b2a38;
//               box-sizing: border-box;
//               border-radius: 0 !important;
//               padding: 0.2rem;
//             }
//             .css-qc6sy-singleValue,
//             .css-14el2xx-placeholder {
//               margin-left: 3rem;
//               font-style: normal;
//               font-weight: normal;
//               font-size: 0.813rem;
//               line-height: 1rem;
//               color: rgba(255, 255, 255, 0.5);
//             }
//             .css-6j8wv5-Input > button,
//             input,
//             optgroup,
//             select,
//             textarea {
//               margin-left: 3rem !important;
//               font-style: normal !important;
//               font-weight: normal !important;
//               font-size: 0.813rem !important;
//               line-height: 1rem !important;
//               color: rgba(255, 255, 255, 0.5) !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// //charging-history
// .charging-history-list--main {
//   .charging-history-list-box {
//     margin-bottom: 1rem;
//     .charging-row {
//       margin: 0;
//       .charging-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .charging-history-date-picker {
//         margin-bottom: 0.5rem;
//       }
//     }
//   }
// }

// //station-charging-summary
// .station-charging-summary-list--main {
//   .station-charging-summary-list-box {
//     margin-bottom: 1rem;
//     .station-charging-summary-row {
//       margin: 0;
//       .station-charging-list-filter {
//         background: #182532;
//         border: 1px solid #1b2a38;
//         box-sizing: border-box;
//         padding: 0.7rem;
//         cursor: pointer;
//         text-align: center;
//         margin-bottom: 0.5rem;
//       }
//       .station-charging-summary-date-picker {
//         margin-bottom: 0.5rem;
//       }
//       .station-charging-summary-select--box {
//         cursor: pointer;
//         .station-charging-summary-label {
//           font-style: normal;
//           font-weight: normal;
//           font-size: 0.813rem;
//           color: #ffffff;
//           line-height: 1rem;
//           .station-charging-summary-select-input {
//             > div {
//               background: #182532;
//               border: 1px solid #1b2a38;
//               box-sizing: border-box;
//               border-radius: 0 !important;
//               padding: 0.2rem;
//             }
//             .css-qc6sy-singleValue,
//             .css-14el2xx-placeholder {
//               margin-left: 3rem;
//               font-style: normal;
//               font-weight: normal;
//               font-size: 0.813rem;
//               line-height: 1rem;
//               color: rgba(255, 255, 255, 0.5);
//             }
//             .css-6j8wv5-Input > button,
//             input,
//             optgroup,
//             select,
//             textarea {
//               margin-left: 3rem !important;
//               font-style: normal !important;
//               font-weight: normal !important;
//               font-size: 0.813rem !important;
//               line-height: 1rem !important;
//               color: rgba(255, 255, 255, 0.5) !important;
//             }
//           }
//         }
//       }
//       .station-charging-summary-download-icon {
//         padding: 0.7rem;
//         text-align: center;
//         background: #182532;
//         cursor: pointer;
//         svg {
//           font-size: 1.3rem;
//           color: #be210b;
//         }
//       }
//     }
//   }
// }

// .css-1r4vtzz {
//   background: #182532 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem 2rem 0rem 0rem !important;
//   // padding: 1rem 6rem 1rem 1rem !important;
//   // display: flex !important;
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem !important;
//   }
//   .css-1gpjby2 {
//     padding: 1rem 1.5rem 1rem 1rem !important;
//     // margin: 0rem 3rem;
//   }
// }

// .css-1pcexqc-container {
//   color: rgb(250, 250, 251) !important;
//   background-color: #223343 !important;
//   .css-1jllj6i-control {
//     background: #223343 !important;
//     border-color: #182532 !important ;
//   }
// }

// .css-11unzgr {
//   overflow-y: auto;
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: rgba(195, 194, 197, 0.4);
//   }

//   .css-1gl4k7y {
//     color: rgb(250, 250, 251) !important;
//     font-weight: normal !important;
//     font-size: 0.9rem !important;
//     padding: 0.5rem 4.9rem;
//   }
// }
// .css-48ayfv {
//   background-color: #182531 !important;
//   color: rgba(255, 255, 255, 0.5) !important ;
//   padding: 0rem !important;
//   // padding: 0.75rem 3rem !important;
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem;
//   }
//   .css-1gpjby2 {
//     padding: 1rem 1.5rem 1rem 1rem !important;
//   }
// }
// .css-1qprcsu-option {
//   background-color: #223343 !important;
//   color: rgb(250, 250, 251) !important;
//   font-weight: normal !important;
//   font-size: 0.9rem !important;
// }
// .css-1vr111p-option {
//   font-size: 0.9rem !important ;
// }
// .css-1vr111p-option:hover {
//   background-color: #223343 !important ;
// }
// .css-1gpjby2 {
//   svg {
//     stroke: currentcolor;
//     fill: currentcolor;
//     // margin-left: 3.5rem;
//     width: 12px;
//     height: 12px;
//     padding: 0 1rem 0 0.5rem;
//     stroke-width: 0;
//     color: rgb(244, 63, 63);
//     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
//     background-size: contain;
//     // padding: 0rem 1.5rem !important
//   }
// }
// .css-48ayfv {
//   .css-1v99tuv {
//     // overflow: visible;
//     padding: 1rem 9.5rem 1rem 1rem;
//   }
// }
// // }
