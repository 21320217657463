@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-pro-sidebar/dist/css/styles.css';
@import '~react-phone-number-input/style.css';

@import './colors';

// Common css styles
@import 'components/general/commonCss';
@import 'components/general/activityItem';
@import 'components/general/informationBlock';
@import 'components/general/searchBox';
@import 'components/general/loader';

// Layout
@import 'components/layout/layout';
@import 'components/layout/sidebar';
@import 'components/layout/header';
@import 'components/layout/dialog';

// Components
@import 'components/inputs/button';
@import 'components/inputs/card';
@import 'components/inputs/input';
@import 'components/inputs/phoneNumberInput';
@import 'components/inputs/select';
@import 'components/inputs/dropdown';
@import 'components/inputs/checkInputBox';
@import 'components/inputs/table';
@import 'components/inputs/tabs';
@import 'components/inputs/avatar';
@import 'components/inputs/datePicker';
@import 'components/inputs/datePickerWithFloatingLabel';
@import 'components/inputs/timePicker';
@import 'components/inputs/switch';
@import 'components/inputs/datePickerInput';
@import 'components/general/sessionHeading';
@import 'components/general/pagination';
@import 'components/general/reactPagination';
@import 'components/general/deleteModal';
@import 'components/inputs/dateTimePicker';
@import 'components/inputs/offCanvas';
@import 'components/inputs/accordion';
@import 'components/general/footer';

// Pages
@import 'page/login/login';
@import 'page/logout/logout';
@import 'page/access/accessdenied';
@import 'page/tenant/tenantAdminDetails';
@import 'page/tenant/tenant';
@import 'page/tenant/tenantAdminList';
@import 'page/station/stationForm';
@import 'page/station/stationList';
@import 'page/charger/chargerDetail';
@import 'page/charger/activityLog';
@import 'page/firmwareUpdate/firmwareUpdate';
@import 'page/customerManagement/customers';
@import 'page/tariffManagement/tariff';
@import 'page/tariffManagement/slots';
@import 'page/station/stationDetails';
@import 'page/vehicle/vehicleForm';
@import 'page/subscriptions/subscriptions';
@import 'page/billingInvoice/billingInvoice';
@import 'page/billingInvoice/smcInvoice.scss';
@import 'page/dataReports/dataReports';
@import 'page/setting/setting';
@import 'page/setting/profileForm';
@import 'page/setting/otpConfirmationModal';
@import 'page/vehicle/vehicle';
@import 'page/notifications/updateNotificationModal';
@import 'page/notifications/notifications';
@import 'page/forgotPassword/forgotPassword';
@import 'page/country/country';
@import 'page/make/make';
@import 'page/model/vehicleModel';
@import 'page/amenity/amenity';
@import 'page/state/state';
@import 'page/city/city';
@import 'page/area/area';
@import 'page/electricitySupplier/electricitySupplier';
@import 'page/stationAccessType/stationAccessType';
@import 'page/oemVendor/oemVendor';
@import 'page/faq/faq';
@import 'page/privacy/privacy';
@import 'page/connectorType/connectorType';
@import 'page/chargeSpeed/chargeSpeed';
@import 'page/oem/oem';
@import 'page/oem/editOemModal';
@import 'page/faqsAndPrivacy/faqsAndPrivacy';
@import 'page/role/role';
@import 'page/permissions/permissions';
@import 'page/dashboard/dashboard';
@import 'page/charger/logInfo';
@import 'page/charger/serverOperation';
@import 'page/charger/serverOperations/serverOperationForm';
@import 'page/charger/serverOperations/serverOperationTable';
@import 'page/chargerState/chargerState';
@import 'page/oemErrorCode/oemErrorCode';
@import 'page/offers/offers';
@import 'page/offers/offersForm';
@import 'page/userGroup/userGroup';
@import 'page/sms/sms';
@import 'page/email/email';
@import 'page/rfidGroup/rfidGroup';
@import 'page/rfid/rfid';
@import 'page/scheduling/publicUsers';
@import 'page/customerManagement/rfidDetailsModal';
@import 'page/masterSetting/masterSetting';
@import 'page/rfidRequests/rfidRequests';
@import 'page/customerManagement/customerDetail.scss';
@import 'page/chargerStatus/chargerStatus.scss';
@import 'page/reserveSlot/ReserveSlot.scss';
@import 'page/billingInvoice/invoice/smc_jv.scss';
@import 'page/billingInvoice/invoice/smc_brv.scss';
@import 'page/partnerManagement/addcpo.scss';
@import 'page/chargeCoin/setting.scss';
@import 'page/admin/admin.scss';
@import 'page/billingInvoice/billing';
@import 'page/downloadreports/downloadreports.scss';
@import 'page/table/table.scss';
@import 'page/rating/rating.scss';
@import 'page/accountDeleteRequest/accountDeleteRequest.scss';
@import 'page/accountDeleteRequest/requestModal';
@import 'page/customerManagement/bookingModal';
@import 'page/customerManagement/scheduleBooking';
@import 'page/module/module';
@import 'page/downloadAdvanceReport/downloadAdvanceReport';
@import 'page/coupon/coupon';
@import 'page/stationAccessList/stationAccessList';
@import 'page/notifications/alerts';
@import 'page/report/Report.scss';

@import 'leaflet/dist/leaflet.css';
