.charge_coin_btn_sec{
    display: flex;
    justify-content: flex-end;
}
.charge_coin_btn_sec_mr{
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
.charge_coin_btn_sec_delete{
    display: flex;
    align-items: flex-end;
    height: 65%;
}

.danger{
    color: $brand-accent !important;
}