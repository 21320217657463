.profile-form-page__main {
  .profile-form-page--inner {
    padding: 1rem;
    padding-bottom: 5rem;
    .profile-form__block {
      .gender-field__block {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .radio-field {
          margin-right: 3rem;
        }
      }
      .save-btn--block {
        margin-top: 2.5rem;
        .profile-save-btn {
          padding: 0.8rem 1rem;
          margin-right: 1rem;
        }
      }
      .cancel-btn--block {
        margin-top: 2.5rem;
        .cancel-btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $brand-accent;
          padding: 0.7rem 2rem;
          background-color: $button-secondary;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .generate-consolidated-invoice-field-main--block {
        margin-top: 2rem;
        .generate-consolidated-invoice-checkbox-input__block {
          .generate-consolidated-invoice--label {
            font-size: 1rem;
            margin-bottom: 0.3rem;
            color: $input-label;
            font-weight: 500;
            margin-right: 1rem;
          }
        }
      }
    }
    .phone-number-input__form-group {
      position: relative;
      margin-bottom: 1rem;
      width: auto;
      flex: 1;
      &.input-disabled {
        cursor: not-allowed;
      }
      .phone-number-input--label {
        font-size: 0.875rem;
        margin-bottom: 0.7rem;
        color: $input-label;
        font-style: normal;
        font-weight: 400;
        line-height: 1.7;
        .require--star {
          color: #da1113;
          font-size: 0.95rem;
          margin-left: 3px;
        }
      }
      .phone-number-input_group {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: auto;
        box-sizing: border-box;
        border-radius: 0.5rem !important;
        border: 1px solid $input-border;
        &:focus-within {
          border-color: $input-focus !important;
        }
        .country-select-box {
          margin-bottom: 0 !important;
          .react-select {
            .react-select__control {
              border: none;
              .react-select__value-container {
                width: auto;
                max-width: 3.5rem;
              }
              .react-select__indicators {
                .react-select__indicator {
                  padding: 0 0.2rem;
                }
              }
            }
            .react-select__menu {
              width: max-content;
            }
          }
        }
        .vertical---line {
          border-left: 2px solid #2f3c47;
          height: auto;
          margin: 0.5rem;
        }
        .PhoneInputInput {
          width: auto;
          flex: 1;
          border: none;
          color: $input-value;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          background: transparent;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          &:focus-visible {
            outline: none !important;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
      .PhoneInputCountryIcon {
        display: none;
      }
      .PhoneInputCountrySelectArrow {
        display: none;
      }
    }
  }
}
