.react-pagination-block__main {
  margin: 1rem 0;
  .react-pagination-block--inner {
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .react-pagination-data-count--block {
      color: $gray-300;
      font-size: 0.9rem;
      font-weight: 600;
    }
    .react-pagination---block {
      .arrow--btn {
        padding: 0.4rem 1.7rem;
        color: $gray-300;
        background: $brand-secondary;
        border-radius: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 0.7rem;
        }
        svg {
          width: 2rem;
          height: 2rem;
        }
        &.disabled {
          cursor: not-allowed;
        }
      }
      .pagination {
        margin: 0px auto;
      }
      .pagination > .active > a {
        background-color: $brand-accent;
        border-color: $gray-500;
        color: #fff;
      }
      .pagination > li > a {
        border: 1px solid $gray-500;
        padding: 10px 14px;
        cursor: pointer;
        color: $gray-300;
        border-radius: 5px;
        margin: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      .pagination > li > a:hover
      {
        background-color: $gray-500;
      }

    }
    .react-pagination-btn {
      border: 1px solid $gray-500;
      padding: 0 0.5rem;
      cursor: pointer;
      color: $gray-300;
      margin-left: auto;
      .has-dropdown {
        background-color: $brand-primary
      }
      .has-dropdown .popup {
        min-width: 47px;
      }
    }
  }
}
