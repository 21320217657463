.session-heading__main {
  position: relative;
  width: auto;
  flex: 1;
  padding: 0 0 1rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid $activity-icon-block-border ;
  &.heading-flex-box {
    display: flex;
    align-items: center;
  }
  .heading--content {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    .heading--text {
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      color: $input-value;
      background-color:#1e3349profile-block__main;
      padding: 0.5rem 1rem; 
      border-radius: 15px;
    }
    .heading--number {
      margin-left: 0.4rem;
    }
  }
  .underline--highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 3.5rem;
  }
  .session-heading--right {
    margin-left: auto;
  }
}
