.list-filter-canvas__container {
  background-color: $brand-primary;
  color: $input-value;

  .filter-canvas__inner {
    padding: 2rem;
    height: 100%;

    .canvas__header-block {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #2e3841;

      .header-name {
        font-size: 1.25rem;
        font-weight: 500;
      }

      .header-close-icon {
        cursor: pointer;

        &:hover {
          background-color: black;
        }
      }
    }

    .canvas__body-block {
      padding: 1rem 0;

      &--inner {
        .form-select-label {
          font-size: 0.95rem;
          font-weight: 400;
          margin-bottom: 0.7rem;
        }

        .filter-select-box {
          margin-bottom: 1.5rem;
        }

        .canvas-selection__block {
          margin-bottom: 1.5rem;

          .selection-block__inner {
            border: 1px solid $input-border;
            border-radius: 10px;
            padding: 0.3rem;

            .row {
              margin: 0;

              .col {
                padding: 0;
              }
            }

            .selection--btn {
              padding: 0.7rem 0.5rem;
              font-size: 0.95rem;
              color: $gray-200;
              text-align: center;
              cursor: pointer;
              border-radius: 10px;
            }

            .active {
              background-color: #be210b26;
              color: $input-value;
            }
          }
        }

        .canvas-btn__block {
          font-size: 0.9rem;

          .apply--btn {
            padding: 0.8rem 1rem;
            border-radius: 15px;
            margin-bottom: 1rem;
          }

          .reset--block {
            padding: 0.7rem;
            color: $gray-200;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: $status-breathing-red;
            }

            .reset--icon {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
