.offer-management-page_main {
  .offer-list-main-block {
    .offer-list-block {
      .offer-search-box {
        .add-offer-btn {
          padding: 0.8rem 1rem;
        }
      }
    }
  }
  .offer-list-table {
    .table-responsive {
      //overflow: inherit !important;
    }
    .loader--block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
