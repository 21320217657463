.update-notification-main_page {
  .update-notification-inner_page {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .update-notification--content {
      .update-notification-setting_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;
        svg {
          color: $semantic-info;
          height: 9rem;
          width: 4rem;
        }
      }
      .update-notification-progressbar {
        margin-bottom: 3rem;
        .update--progressbar {
          background-color: $gray-300;
          height: 0.4rem;
          width: 24rem;
          .progress-bar {
            background-color: $special-excel;
          }
        }
      }
      .update-notification--name {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 7rem;
        font-size: 1.5rem;
      }
    }
    .update-notification-btn {
      .update--btn {
        padding: 0.6rem 2.2rem;
      }
    }
  }
}
