.cpo_menu_btns {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  .cpo_menu_btn {
    background-color: transparent;
    border: 1px solid $input-border;
  }
}
.cpo_sec {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .business_details_sec,
  .address_details_sec {
    width: 48%;
    .cpo_title {
      padding-bottom: 25px;
    }
  }
  .address_details_sec {
    width: 48%;
  }
}

.force-upgrade-field-main--block,
.rfid-request-field-main--block {
  margin-top: 3rem;
  .force-upgrade-checkbox-input__block,
  .rfid-request-checkbox-input__block {
    .force-upgrade--label,
    .rfid-request--label {
      font-size: 1rem;
      margin-bottom: 0.7rem;
      color: $input-label;
      font-weight: 500;
      margin-right: 1rem;
    }
  }
}

.copy-clipboard {
  float: right;
  position: relative;
  bottom: 65px;
  margin-right: 20px;
  z-index: 999;
  border: none;
  font-weight: 600;
  outline: none;
  .copy-icon {
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
  }
}
.cpo_save_btn {
  display: flex;
  justify-content: flex-end;
  .cpo_save_btn__1 {
    width: 150px;
  }
}
.cpo_more {
  position: relative;
  .cpo_pull {
    border: 1px solid $input-border;
    border-radius: 5px;
    background-color: $gray-700;
    position: absolute;
    top: -100px;
    right: 0;
    z-index: 2;
    .btn_color {
      border: none;
      background-color: transparent !important;
    }
  }
}
.cpo_title {
  padding-bottom: 25px;
}

.app-icons {
  display: flex;
  gap: 5px;
}
.station-btn{
  padding: 0.8rem 1.7rem;
}
.charger-btn{
  padding: 0.8rem 1.7rem;
}
//CPO Detail
.customerDetail-page__main {
  .page--info {
    display: flex;
    justify-content: space-between;
    .page--info--inner {
      display: flex;
      align-items: center;
      .customer-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .customer-name {
          font-style: normal;
          font-weight: 500;
          font-size: 1.625rem;
          line-height: 2.063rem;
          color: $input-value;
          margin-bottom: 0.7rem;
        }
        .customer-info {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value;
        }
      }
    }
    .customerDetail-dropdown_btn {
      .dropdown-icon > svg {
        color: $gray-300;
        font-size: 2rem;
      }
    }
  }
  .customerDetail-table {
    margin-top: 1rem;
    .customerDetail-table-heading {
      margin: 1rem;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1.4rem;
      border-bottom: 1px solid $tab-active-color;
    }
    .customerDetail-list-table {
      .item-icon {
        padding-right: 1rem;
      }
    }
  }
}

// CPOInformation-tab
.customerProfile-details__main {
  .customerProfile-details-inner {
    .customerProfile--content {
      margin-bottom: 1rem;
      .customerProfile-details--wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }
}

//Connectivity-tab

.customerBooking-table {
  margin-top: 1rem;
  .customerBooking-table-heading-row {
    border-bottom: 1px solid $tab-active-color;
    .customerBooking-table-heading {
      margin: 1rem;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1.4rem;
    }
    .customerBooking-filter-block {
      display: flex;
      padding: 0.9rem;
      .customerBooking-filter-icon {
        display: flex;
        align-items: center;
        .customerBooking-filter-label {
          padding-left: 0.5rem;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
      .customerBooking-arrow__icon {
        color: $brand-accent;
        margin-left: auto;
      }
    }
    .customerBooking-filter-dropdown {
      min-width: 12rem;
      margin-right: 1rem;
      @media screen and (max-width: 1440px) {
        min-width: 10rem;
      }
      @media screen and (max-width: 1024px) {
        min-width: 12rem;
      }
      @media screen and (max-width: 768px) {
        min-width: 12rem;
      }
    }
    .customerBooking-search-button {
      border: none;
      cursor: pointer;
      border-radius: 15px;
      padding: 0.8rem 1rem;
      font-size: 0.9rem;
    }
    .customerBooking-search-excel-block {
      padding-left: 0.5rem;
      // padding-top: 0.5rem;
    }
    .customerBooking-excel-icon {
      cursor: pointer;
      margin-top: 0.5rem;
      margin-left: 1.5rem;
      @media screen and (max-width: 1024px) {
        margin-left: 0rem;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0rem;
      }
      .download-table-xls-button {
        color: $special-excel;
        background: transparent;
        border: none;
        svg {
          height: 2rem;
        }
      }
    }
  }
  .customerBooking-list-table {
    .item-icon {
      padding-right: 1rem;
    }
  }
}

.display_none {
  display: none !important;
}

.cancel_btn {
  background-color: $gray-700;
  border-color: $input-border;
  &:hover {
    background-color: $input-border;
    border-color: $gray-700;
  }
  &:focus {
    background-color: $input-border;
    border-color: $gray-700;
  }
}
