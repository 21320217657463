.chart-container {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  /* set the aspect ratio of the chart */
}

@media (min-width: 276px) and (max-width: 575px) {
  /* adjust the height and width for screens less than 576px (mobile) */
  .chart-container {
    height: 370px;
    padding-bottom: 0;
  }

  #temp {
    padding: 20px;
  }

  #flextemp {
    /* background-color: red; */
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  /* adjust the height and width for screens larger than 576px (mobile) */
  .chart-container {
    height: 370px;
    padding-bottom: 0;
  }

  #temp {
    padding: 50px;
  }
}

@media (min-width: 768px) {
  /* adjust the height and width for screens larger than 768px (tablet) */
  .chart-container {
    height: 370px;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  /* adjust the height and width for screens larger than 992px (desktop) */
  .chart-container {
    height: 370px;
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  /* adjust the height and width for screens larger than 1200px (large screen) */
  .chart-container {
    height: 370px;
    padding-bottom: 0;
  }

  #flextemp {
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
  }

  #tempcard {
    width: 48%;
    margin: 5px;
  }

  #tempcard2 {
    width: 100%;
  }

  #my-datepicker {
    cursor: default;
  }
}

.not-found {
  text-align: center;
  padding-top: 325px;
  color: #8d9dac;
}

.datanot-found {
  margin-left: 15px;
  padding: 8px;
  font-size: 24px;
}
.dashboard-details__block .row div[class^='col-']:nth-child(odd) .dashboard-icon svg {
  color: rgb(60, 124, 221);
}
.dashboard-details__block .row div[class^='col-']:nth-child(even) .dashboard-icon svg {
  color: rgb(190, 33, 11);
}
