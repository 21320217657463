.user-group-table-editIcon {
  margin-right: 1.5rem;
  font-size: 1.2rem;
  color: #3c7cdd;
  cursor: pointer;
}
.user-group-table-deleteIcon {
  font-size: 1.2rem;
  color: #be210b;
  cursor: pointer;
}

.time_sec {
  width: 100%;
  display: flex;
  align-items: center;
  .custome_timedate {
    display: flex;
    width: 500px !important;
    height: 50px;
    border-radius: 10px;
    background-color: transparent !important;
    .ctd_selecter {
      padding: 4px;
      width: 100% !important;
      height: 105% !important;
      font-size: 20px !important;
      border-radius: 10px !important;
      color: #fff;
      outline: none;
      border: 1px solid #3b4a56 !important;
      background-color: transparent !important;
    }
  }
}
