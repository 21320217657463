.configurationmodule-table {
  tbody {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    tr {
      display: flex;
      flex: 0 0 33.33%;
      td {
        display: flex;
        place-content: flex-end;
        flex: 1;
      }
      td:first-child {
        place-content: flex-start;
      }
    }
  }
}
