.avatar {
  border-radius: 50%;
  width: 4.25rem;
  height: 4.25rem;
  position: relative;
  overflow: unset;
  margin-right: 10px;
  cursor: pointer;
  .avatarImg {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    border-radius: 50%;
  }
}
