.invoice-page_main {
  .smc-invoice {
    .invoice-table-header {
      margin: 50px 0 10px 0;
      display: flex;
      justify-content: space-between;
      .invoice-table-1 {
        display: flex;
        border: 1px solid;
        width: 45%;
        .invoice-table-1-row {
          padding: 5px;
          margin-right: 40px;
        }
        .invoice-table-1-col {
          padding: 10px;
          padding-left: 0;
        }
      }
    }
    .invoice-header {
      text-align: center;
      .smc_logo {
        position: absolute;
        top: 20;
        left: 100px;
      }
    }

    table {
      tr {
        th {
          text-align: center;
        }
        th,
        td {
          border: 1px solid;
          padding: 5px;
        }
      }

      .invoice-row-height {
        height: 100px;
      }
      .text-align-right {
        text-align: right;
      }
      .text-align-center {
        text-align: center;
      }
      .space {
        padding: 0 40px;
      }
    }
  }
}
