.serverOperation-title-name {
  margin-bottom: 1.5rem;
}
.server-operation-table-page_main {
  .server-operation-table-main-block {
    .server-operation-table-block {
      .server-operation-search-box {
        .add-vehicle-btn {
          padding: 0.8rem 2rem;
          
        }
      }
      .server-operation-table_list {
        .table-responsive {
          .record-list-table {
            .edit-icon {
              margin-right: 1.5rem;
              font-size: 1.2rem;
              color: $semantic-info;
              cursor: pointer;
            }
            .delete-icon {
              font-size: 1.2rem;
              color: $brand-accent;
              cursor: pointer;
            }
          }
        }
      }
      .loader_block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  //for a server operation table
  @media screen and (max-width: 1024px) {
    .table-responsive {
      max-width: 485px !important;
    }
  }
  @media screen and (max-width: 1440px) {
    .table-responsive {
      max-width: 585px;
    }
  }
}
