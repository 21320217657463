.account-delete-request-page__main {
  .account-details--block {
    // margin-bottom: 1.5rem;
    .account-details-search--block {
      .date-picker {
        margin-bottom: 1rem;
      }
      .accountList-add-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .accountList-add-box-Btn {
          padding: 0.8rem 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .account-details-inner {
      .account-details-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        .account-details-wrapper {
          margin-bottom: 1.5rem;
          box-sizing: border-box;
          .account-info--box {
            display: flex;
            border: 1px solid $special-billing;
            padding: 0.5rem;
            border-radius: 8px;
            .account-info-icon {
              margin-right: 1rem;
              font-size: 2rem;
              padding: 1.3rem;
              box-sizing: border-box;
              border-radius: 8px;
              text-align: center;
              align-items: center;
              display: flex;
              justify-content: center;
            }

            .account-info--wrapper {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              .total-account {
                font-style: normal;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.25rem;
              }

              .info-text {
                font-style: normal;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 0.938rem;
              }
            }
          }
        }
      }
    }
  }
  .new-account-list {
    // margin-bottom: 1.5rem;
    .account-text {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $input-value;
      margin-bottom: 1rem;
    }
    .account-list-table {
      .list-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $gray-300;
        cursor: pointer;
      }
      .data-list--cell {
        width: 4rem;
        .list-items {
          white-space: pre-wrap;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .account-name-list {
        display: flex;
        align-items: center;
        .account-avatar {
          height: 1.7rem;
          width: 1.7rem;
        }
      }
      .accountList-table-editIcon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $semantic-info;
        cursor: pointer;
      }
      .accountList-table-deleteIcon {
        font-size: 1.2rem;
        color: $brand-accent;
        cursor: pointer;
      }
      .account-list-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $gray-300;
        cursor: pointer;
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      tr {
        // cursor: pointer;
        // &:hover {
        //   td {
        //     background-color: $gray-600;
        //   }
        // }
        .avatar-with-name {
          display: flex;
          place-items: center;
          svg {
            margin-right: 0.5em;
          }
          .account-name {
            text-transform: capitalize;
          }
        }
        .wallet-rupee-icon {
          display: flex;
          align-items: center;
        }
        .app-icons {
          display: flex;
          gap: 5px;
        }
        .accountList-approve-btn{
          background-color: #109b72;
          padding: 0.8rem 1rem;
        }
        .accountList-reject-btn{
          padding: 0.8rem 1.7rem;
        }
      }
      .record-list-table {
        border-collapse: separate;
        border-spacing: 0 10px;
        margin-top: -10px;
        th {
          border-radius: 0px;
          border-color: $tab-active-color;
          &:hover {
            border-radius: 10px;
          }
        }
      }
    }
    .main-card-container {
      border-radius: 0px 0px 20px 20px;
      border: 0px;
    }
  }
  .users-group-list--block {
    .user-group-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-group-text {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value;
      }
      .upload-file-block {
        .upload-file--input {
          display: none;
        }
        .upload-file-inner {
          cursor: pointer;
          .upload-file-text {
            font-style: normal;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $brand-accent;
            padding-left: 0.8rem;
          }
        }
      }
    }
    .users-group-list {
      .users-group-list-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        color: $input-value;
        background-color: $brand-primary;
        font-size: 0.875rem;
        white-space: nowrap;
        border-bottom: 1px solid $gray-500;
        text-align: left;
        vertical-align: middle;
        .user-group--name {
          display: flex;
          align-items: center;
        }
        .list-text {
          cursor: pointer;
          padding-left: 1rem;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value;
        }
        .users-group-list-icon {
          cursor: pointer;
          color: $brand-accent;
          font-size: 1.4rem;
          padding: 0.5rem;
        }
      }
    }
  }
}

// Form css
.account-form-page__main {
  .account-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $tab-active-color;
    .account-header-name {
      font-size: 1.25rem;
    }
    .close___btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $light-table-header;
      }
    }
  }
  .account-form-body__block {
    padding: 1rem 2rem;
    .account-form--block {
      .cutomer-input--field {
        margin-left: 1rem;
      }
      .form-btn__block {
        text-align: right;
        margin-top: 1rem;
        .form_btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .cancel____btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $brand-accent;
          &:hover {
            background-color: $button-secondary;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
