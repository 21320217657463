.faqsAndPrivacy-details-page__main {
  .faqsAndPrivacy-details--tab {
    .tab-block {
      .tab-nav-block {
        .tab-nav__item {
          width: 100%;
        }
        .faqsAndPrivacy--info {
          display: flex;
          align-items: center;
          justify-content: center;
          .faqsAndPrivacy-name {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

//faq/privacy list
.faq-page__main,
.privacy-page__main {
  .page-top--block {
    display: flex;
    justify-content: flex-end;
  }
  .page-list__block-main {
    padding: 1rem;
    .loader--block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
