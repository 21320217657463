.customer-management-page__main {
  .customer-details--block {
    // margin-bottom: 1.5rem;
    .customer-details-search--block {
      .date-picker {
        margin-bottom: 1rem;
      }
      .customerList-add-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .customerList-add-box-Btn {
          padding: 0.8rem 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .customer-details-inner {
      .customer-details-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        .customer-details-wrapper {
          margin-bottom: 1.5rem;
          box-sizing: border-box;
          .customer-info--box {
            display: flex;
            border: 1px solid $special-billing;
            padding: 0.5rem;
            border-radius: 8px;
            .customer-info-icon {
              margin-right: 1rem;
              font-size: 2rem;
              padding: 1.3rem;
              box-sizing: border-box;
              border-radius: 8px;
              text-align: center;
              align-items: center;
              display: flex;
              justify-content: center;
            }

            .customer-info--wrapper {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              .total-customer {
                font-style: normal;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.25rem;
              }

              .info-text {
                font-style: normal;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 0.938rem;
              }
            }
          }
        }
      }
    }
  }
  .new-customer-list {
    // margin-bottom: 1.5rem;
    .customer-text {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $input-value;
      margin-bottom: 1rem;
    }
    .customer-list-table {
      .list-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $gray-300;
        cursor: pointer;
      }
      .data-list--cell {
        width: 4rem;
        .list-items {
          white-space: pre-wrap;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .customer-name-list {
        display: flex;
        align-items: center;
        .customer-avatar {
          height: 1.7rem;
          width: 1.7rem;
        }
      }
      .customerList-table-editIcon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $semantic-info;
        cursor: pointer;
      }
      .customerList-table-deleteIcon {
        font-size: 1.2rem;
        color: $brand-accent;
        cursor: pointer;
      }
      .customer-list-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $gray-300;
        cursor: pointer;
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      tr {
        cursor: pointer;
        &:hover {
          td {
            background-color: $gray-600;
          }
        }
        .avatar-with-name {
          display: flex;
          place-items: center;
          svg {
            margin-right: 0.5em;
          }
          .customer-name {
            text-transform: capitalize;
          }
        }
        .wallet-rupee-icon {
          display: flex;
          align-items: center;
        }
        .app-icons {
          display: flex;
          gap: 5px;
        }
        .list-dot-icon {
          color: #f5604c;
          display: inline-flex;
          font-size: 1.5rem;
        }
        .list-text {
          color: #f5604c;
          font-style: normal;
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
      .record-list-table {
        border-collapse: separate;
        border-spacing: 0 10px;
        margin-top: -10px;

        thead {
          tr {
            border-bottom: 0px;
            display: flex;
            flex-direction: row;

            th {
              border-color: $tab-active-color;
              flex: 1;
              border-radius: 0px;
              &:hover {
                border-radius: 10px;
              }
            }

            th:first-child {
              flex: 24.5%;
            }

            th:nth-child(2) {
              flex: 15%;
            }

            th:nth-child(3) {
              flex: 10%;
            }
          }
        }

        tbody {
          tr {
            display: flex;
            flex-direction: row;
            border-bottom: 0px;

            // td {
            //   background-color: transparent;
            //   border-bottom: 0px;
            //   flex: 1;
            // }
            td {
              border: 1.023px solid $tab-active-color;
              border-style: solid none;
              border-radius: 0px;
              flex: 1;
              &:first-child {
                border-left-style: solid;
                border-top-left-radius: 20.453px;
                border-bottom-left-radius: 20.453px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              }
              &:last-child {
                border-right-style: solid;
                border-bottom-right-radius: 20.453px;
                border-top-right-radius: 20.453px;
              }
            }
          }
        }
        .customerList-tr {
          display: flex;
          flex-direction: column;
          border-radius: 20.453px;
          border: 1.023px solid $tab-active-color;
          margin: 10px 0px;
        }

        .customerList-tr tr td:first-child,
        .customerList-tr tr th:first-child {
          flex: 24.5% !important;
        }
        .customerList-tr tr td:nth-child(2),
        .customerList-tr tr th:nth-child(2) {
          flex: 15% !important;
        }
        .customerList-tr tr td:nth-child(3),
        .customerList-tr tr th:nth-child(3) {
          flex: 10% !important;
        }
      }
    }
    .main-card-container {
      border-radius: 0px 0px 20px 20px;
      border: 0px;
    }
  }
  .users-group-list--block {
    .user-group-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-group-text {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value;
      }
      .upload-file-block {
        .upload-file--input {
          display: none;
        }
        .upload-file-inner {
          cursor: pointer;
          .upload-file-text {
            font-style: normal;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $brand-accent;
            padding-left: 0.8rem;
          }
        }
      }
    }
    .users-group-list {
      .users-group-list-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        color: $input-value;
        background-color: $brand-primary;
        font-size: 0.875rem;
        white-space: nowrap;
        border-bottom: 1px solid $gray-500;
        text-align: left;
        vertical-align: middle;
        .user-group--name {
          display: flex;
          align-items: center;
        }
        .list-text {
          cursor: pointer;
          padding-left: 1rem;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value;
        }
        .users-group-list-icon {
          cursor: pointer;
          color: $brand-accent;
          font-size: 1.4rem;
          padding: 0.5rem;
        }
      }
    }
  }
}

// Form css
.customer-form-page__main {
  .customer-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $tab-active-color;
    .customer-header-name {
      font-size: 1.25rem;
    }
    .close___btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $light-table-header;
      }
    }
  }
  .customer-form-body__block {
    padding: 1rem 2rem;
    .customer-form--block {
      .cutomer-input--field {
        margin-left: 1rem;
      }
      .margin_top {
        margin-top: 12px;
        font-size: small;
      }
      .font-color {
        margin-bottom: 10px;
        color: $special-admin;
      }
      .time_sec {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        .to_sec {
          padding: 8px;
          margin: auto;
        }
        .custome_time {
          display: flex;
          width: 45%;
          height: 50px;
          border-radius: 10px;
          background-color: transparent !important;
          flex-wrap: wrap;
          .ct_selecter {
            padding: 0.5em;
            width: 100% !important;
            height: 100% !important;
            font-size: 14px !important;
            border-radius: 10px !important;
            color: $input-value;
            outline: none;
            border: 1px solid $input-border !important;
            background-color: transparent !important;
          }
        }
      }
      .form-btn__block {
        text-align: right;
        margin-top: 1rem;
        .form_btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .cancel____btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $brand-accent;
          &:hover {
            background-color: $button-secondary;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

//wallet Transaction
.wallet-transaction_main {
  .wallet-transaction-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $tab-active-color;
    .wallet-transaction-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $light-table-header;
      }
    }
  }
  .wallet-transaction_form {
    padding: 1rem 2rem;
    .wallet-transaction_button {
      text-align: right;
      .wallet-transaction-btn {
        padding: 0.8rem 1.7rem;
        font-weight: 500;
      }
      .cancel_btn {
        margin-right: 1rem;
        background-color: transparent;
        border-color: transparent;
        color: $brand-accent;
        &:hover {
          background-color: $button-secondary;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

//booking Form
.booking-form-body__block {
  padding: 1rem 2rem;

  .booking-form--block {
    .row {
      position: relative;
      margin-bottom: 1em;
      .col.btn_del {
        position: absolute;
        text-align: right;
        .primary-button {
          padding: 0px;
        }
      }
    }
    .booking-slot-add-btn {
      button {
        padding: 0px;
      }
    }
    .booking-form-btn__block {
      margin-top: 1rem;

      .booking-form-btn {
        padding: 1rem 1.5rem;
        font-weight: 500;
      }
      .margin_top {
        margin-top: 12px;
      }
    }
    .margin_top {
      margin-top: 12px;
      font-size: small;
    }
    .font-color {
      margin-bottom: 10px;
      color: $special-admin;
    }
    .time_sec {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      .to_sec {
        padding: 8px;
        margin: auto;
      }
      .custome_time {
        display: flex;
        width: 45%;
        height: 50px;
        border-radius: 10px;
        background-color: transparent !important;
        flex-wrap: wrap;
        .ct_selecter {
          padding: 0.5em;
          width: 100% !important;
          height: 100% !important;
          font-size: 14px !important;
          border-radius: 15px !important;
          color: $input-value;
          outline: none;
          border: 1px solid $input-border !important;
          background-color: transparent !important;
        }
      }
    }
    .useChargeCoin-field-main--block {
      margin-top: 2rem;
      .useChargeCoin-checkbox-input__block {
        .useChargeCoin--label {
          font-size: 1rem;
          margin-bottom: 0.7rem;
          color: $input-label;
          font-weight: 500;
          margin-right: 1rem;
        }
      }
    }
  }
}

.booking-solt-text-align {
  text-align: center;
}
.booking-close-btn {
  width: 40px;
  padding: 0.1rem;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  text-align: center;
  &:hover {
    background-color: $light-table-header;
  }
}

.field--btn-prime {
  color: $semantic-info !important;
}

.booking-slot-list-btn {
  height: 65%;
  background-color: $gray-600;
  border: $gray-600;
  &:hover {
    background-color: $brand-secondary;
    border: $gray-600;
  }
  &:focus {
    background-color: $brand-secondary;
  }
}

.booking-slot-btns-sec {
  display: flex;
  justify-content: space-between;
  place-items: center;
  .booking-slot-submit-btn {
    button {
      padding: 0.8rem 1rem !important;
    }
  }
}
@media (max-width: 768px) {
  .booking-form-body__block {
    padding: 1em;
  }
}
