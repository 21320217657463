//rfid-Setting
.rfid-setting_main {
  .rfid-setting-form-card {
    .rfid-setting-form__block {
      .rfid-setting-btn--block {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .rfid-setting-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}

//app-setting
.app-setting_main {
  .app-setting-form-card {
    .app-setting-form__block {
      .uploaded-image {
        padding-right: 1rem;
        img,
        svg {
          width: 3rem;
          height: 3rem;
        }
      }
      .force-upgrade-field-main--block,
      .rfid-request-field-main--block {
        margin-top: 3rem;
        .force-upgrade-checkbox-input__block,
        .rfid-request-checkbox-input__block {
          .force-upgrade--label,
          .rfid-request--label {
            font-size: 1rem;
            margin-bottom: 0.7rem;
            color: $input-label;
            font-weight: 500;
            margin-right: 1rem;
          }
        }
      }
      .app-setting-btn--block {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .app-setting-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
