.log-list--main {
  .log-list-box {
    .log-row {
      align-items: flex-start;
      .search-box--block {
        display: flex;
        align-items: center;

        .search--box {
          flex: 1;
        }
      }

      .sequence-search--box {
        width: 450px;
      }

      .log-filter-block {
        display: flex;
        padding: 0.75rem;

        .log-filter-icon {
          display: flex;
          align-items: center;

          .log-filter-label {
            padding-left: 0.5rem;
            font-size: 0.875rem;
            font-weight: 600;
          }
        }

        .log-arrow__icon {
          color: $brand-accent;
          margin-left: auto;
        }
      }

      .log-filter-dropdown {
        min-width: 12rem;
        margin-right: 1rem;
        border-width: 2px;
        border-radius: 15px;
        @media screen and (max-width: 1024px) {
          min-width: 10rem;
        }

        @media screen and (max-width: 768px) {
          min-width: 8rem;
        }
      }

      .log-date-picker {
        margin-bottom: 0.5rem;

        @media screen and (min-width: 1440px) {
          margin-left: 1.5rem;
        }
        input {
          border-width: 0px;
        }
      }
    }

    .log-search-excel-block {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .log-search-box {
        .log-search-button {
          border: none;
          cursor: pointer;
          border-radius: 15px;
          padding: 0.8rem 1rem;
          font-size: 0.9rem;
        }
      }
    }

    .eye-icon {
      margin-left: 1rem;
      cursor: pointer;
    }

    .excel-icon {
      margin-left: 0.5rem;
      cursor: pointer;

      .download-table-xls-button {
        color: $special-excel;
        background: transparent;
        border: none;

        svg {
          height: 1.3rem;
        }
      }
    }

    .json-data__block {
      white-space: unset !important;

      .json--data {
        max-width: 550px;
      }
    }
  }

  .loader--block_ {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//table
.modal-content {
  // width: auto;
  .model_main_content {
    display: flex;
    padding: 1.5rem;

    .title_name {
      flex-grow: 1;
      color: $input-value;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .table-close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background-color: $light-table-header;
      }
    }
  }
}
