.select-input-form-group {
  position: relative;
  margin-bottom: 1.5rem;
  .form-select-label {
    font-size: 0.875rem;
    margin-bottom: 0.7rem;
    color: $input-label;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    .require--star {
      color: $brand-accent;
      font-size: 0.95rem;
      margin-left: 3px;
    }
  }
}
.react-select {
  background-color: none;

  &.react-select--times {
    .react-select__control {
      min-height: 40px;
      border: 1px solid #ededed !important;
      background: transparent;
      width: 112px;

      .react-select__value-container {
        padding: 0 16px;
        justify-content: center;
      }

      .react-select__placeholder {
        left: 0;
        right: 0;
        text-align: center;
      }
    }

    .react-select__menu {
      .react-select__option {
        text-align: center;
      }
    }

    .react-select__indicators {
      display: none;
    }
  }

  .react-select__control {
    background-color: $brand-primary;
    border: 1px solid $input-border;
    border-radius: 15px !important;
    box-shadow: none !important;
    min-height: 32px;
    cursor: pointer;

    &.react-select__control--is-focused {
      border-color: $input-focus;
    }

    .react-select__input {
      input {
        color: $input-value;
        font-weight: 300;
      }
    }

    .react-select__value-container {
      padding: 0.5rem;
      cursor: text;

      .selected-indicator {
        display: none;
      }

      .react-select__input-container {
        color: $input-value;
      }

      .react-select__multi-value-custom-container {
        margin: 2px;

        .react-select__multi-value {
          margin: 0;
        }

        &.is-colored {
          .react-select__multi-value {
            background-color: transparent;

            .react-select__multi-value__label {
              background-color: inherit;
              font-weight: bold;
              font-size: 10px;
              color: #fff;
              padding: 0 8px;
              border-radius: 1px;
              line-height: 17px;
              text-transform: uppercase;
            }
          }

          .react-select__multi-value__remove {
            background: #fff !important;
            border-radius: 0;
          }
        }
      }

      .is-over-max {
        .selected-indicator {
          display: block;
        }

        .react-select__multi-value {
          display: none;
        }

        + .is-over-max {
          display: none;
        }
      }

      .react-select__multi-value {
        background-color: #ededed;

        .react-select__multi-value__remove {
          background: none !important;
          cursor: pointer;

          &:hover {
            color: $brand-accent;
          }
        }
      }
    }

    .react-select__single-value {
      color: $input-value;
      font-size: 14px;
      top: 48%;
    }

    .react-select__placeholder {
      color: #8c8c8c;
      font-size: 14px;
      line-height: 1;
      margin-left: 0;
      left: 2px;
    }
  }

  .menu-is-open {
    .react-select__dropdown-indicator:after {
      transform: rotate(180deg);
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__clear-indicator {
    padding: 2px;
    margin-right: 8px;

    &:hover {
      color: #dbdbdc;
    }

    svg {
      height: 16px;
    }
  }

  .react-select__dropdown-indicator {
    padding: 0 1rem 0 0.5rem;
    &:after {
      content: '';
      transition: 0.3s all;
      width: 12px;
      height: 12px;
      background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
      background-size: contain;
    }

    svg {
      display: none;
    }
  }

  .react-select__menu {
    box-shadow: none;
    border: 1px solid $input-border;
    border-radius: 15px;
    margin-top: 4px;
    z-index: 99;
    background: $brand-secondary;

    .react-select__menu-list {
      padding: 8px 20px;
      @extend.scrollbar;

      &.react-select__menu-list--is-multi {
        padding: 5px;

        .react-select__option {
          padding: 10px 16px;
          border-radius: 15px;

          &.react-select__option--is-selected {
            background-color: transparent !important;

            &:not(:hover) {
              color: $input-value;
            }
          }
        }
      }
    }

    .react-select__menu-notice {
      color: #8c8c8c;
      line-height: 1;
    }

    .react-select__option {
      color: $input-value;
      font-weight: 300;
      padding: 0.3rem 0.5rem;
      border-radius: 10px;

      &.react-select__option--is-focused,
      &.react-select__option--is-selected {
        background-color: $brand-primary;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}

.scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(33, 34, 36, 0.18);
    border-radius: 9px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9px;
  }
}
