.billing-invoicing__main {
  .billing-invoicing--block{
  .billing--box {
    margin-bottom: 1rem;
    .billing-content--box {
      .billing-info--box {
        display: flex;
        border: 1px solid $special-billing;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0.9rem;
        margin-bottom: 1rem;
        .billing-info-icon {
          border: 1px solid $special-billing;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 1rem;
          > svg {
            color: $semantic-info;
            font-size: 1.3rem;
            box-sizing: border-box;
          }
        }
        .billing-info--wrapper {
          display: flex;
          align-items: center;
          margin-left: 1rem;
          .billing-title {
            font-style: normal;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.563rem;
            color: $input-value;
            margin-right: 0.5rem;
          }
          .billing-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $input-value;
          }
        }
      }
    }
    .billing-unitConsumed-revenue_chart_main {
      .billing-unitConsumed-revenue_chart_inner {
        display: flex;
        margin-bottom: 1rem;
        .billing-unitConsumed-revenue_chart_block {
          display: flex;
          flex-grow: 1;
          align-items: center;
          .billing-unitConsumed-revenue_name {
            margin-right: 1.5rem;
            color: $gray-200;
            cursor: pointer;
            &.active {
              color: $input-value;
            }
          }
          .billing-unitConsumed-revenue__btn {
            .billing-unitConsumed-revenue-inner-btn {
              border: none;
              font-size: 0.85rem;
              background: #293d4f;
            }
          }
        }
        .billing-unitConsumed-revenue_chart_content {
          display: flex;
          align-items: center;
          .billing-unitConsumed-revenue__days {
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $gray-200;
            cursor: pointer;
            margin-right: 1rem;
            &.active {
              color: $input-value;
            }
          }
          .billing-unitConsumed-revenue_days {
            margin-right: 1rem;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $gray-200;
            cursor: pointer;
            &.active {
              color: $input-value;
            }
          }
          .billing-unitConsumed-revenue_line {
            border: 1px solid $gray-500;
            height: 1rem;
            margin: 0 0.5rem;
          }
          .billing-unitConsumed-revenue_filter_icon {
            display: flex;
            align-items: center;
            color: $gray-200;
            svg {
              margin-left: 1rem;
            }
            .billing-unitConsumed-revenue_filter_name {
              margin-left: 0.5rem;
            }
          }
        }
      }
      .billing-unitConsumed-revenue_chart-img {
        .billing-unitConsumed-revenue_chart {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  .main-card-container {
    max-width: 100rem;
    @media screen and (max-width: 1440px) {
      max-width: 69rem !important;
    }
    @media screen and (max-width: 1024px) {
      max-width: 44rem !important;
    }
    .tab-block {
      border-bottom: 1px solid $tab-active-color;
      .invoice--text {
        margin-top: 1rem;
      }
      .tab-nav-block {
        border-bottom: none;
        @media screen and (max-width: 768px) {
          margin-bottom: 1.5rem;
        }
        @media screen and (max-width: 1440px) {
          margin-bottom: 1.5rem;
        }
      }
      .tab-nav__item {
        padding: 1rem 1.5rem;
        @media screen and (max-width: 1440px) {
          padding: 1rem 0.5rem;
        }
      }
      .invoice-search-excel-block {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.05rem;
        .invoice-search-box {
          margin-left: 1rem;
          .invoice-search-button {
            border: none;
            cursor: pointer;
            border-radius: 15px;
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
          }
        }
      }
      .invoice-search-excel-block.bulk_downlaod {
        .invoice-search-box {
          margin-left: 0px;
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
      // .filterData---invoice {
      //   .invoice-filter-block {
      //     display: flex;
      //     padding: 0.9rem;
      //     .invoice-filter-icon {
      //       display: flex;
      //       align-items: center;
      //       .invoice-filter-label {
      //         padding-left: 0.5rem;
      //         font-size: 0.875rem;
      //         font-weight: 600;
      //       }
      //     }
      //     .invoice-arrow__icon {
      //       color: $brand-accent;
      //       margin-left: auto;
      //     }
      //   }
      //   .invoice-filter-dropdown {
      //     min-width: 12rem;
      //     margin-right: 1rem;
      //     @media screen and (max-width: 1024px) {
      //       min-width: 10rem;
      //     }
      //     @media screen and (max-width: 768px) {
      //       min-width: 8rem;
      //     }
      //     @media screen and (max-width: 1440px) {
      //       min-width: 10rem;
      //     }
      //   }
      // }
    }
  }
  .invoice-list--table {
    .invoice-list-icon {
      margin-right: 1.5rem;
      font-size: 1.2rem;
      color: #845f7c;
      cursor: pointer;
    }
    .invoice-vehicle-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
}

//Download-invoice
.invoice-page_main {
  .print--btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .invoice-page--wrapper {
    background-color: $input-value;
    color: $light-table-header;
    height: calc(100vh - 5.5rem);
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }
  .iframe-class {
    height: 0;
    width: 0;
    position: absolute;
  }
}
// .icon-pos{
//   height: 100%;
//   display: flex;
//   align-items: center;
// }
