.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $brand-primary;
  color: $input-value;
  gap: 15px;
  .spinner {
    width: 3rem;
    height: 3rem;
    border: 5px solid $brand-accent;
    border-top-color: $brand-accent;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
  }
  p {
    margin-bottom: 0px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
