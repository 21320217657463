//logout modal

.logout-modal-main_page {
  border-radius: 30px;
  overflow: hidden;
  .logout-modal--inner {
    padding: 2rem 2.5rem;
    background: $brand-primary;
    border-radius: 30px;
    overflow: hidden;
    .logout-modal---block_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $input-value;
      font-style: normal;
      margin-bottom: 3rem;
      .inner--block_title {
        .title {
          font-weight: 500;
          font-size: 3.5rem;
          letter-spacing: 1px;
          margin-bottom: 0.5rem;
          text-align: center;
        }
        .sub-title {
          font-weight: 300;
          font-size: 0.95rem;
          letter-spacing: 1px;
          color: $input-label;
          text-align: center;
        }
      }
    }
    .logout-modal-input-box__block {
      .col-lg-4 {
        flex: 1;
      }
      .logout-model--btn {
        width: 100%;
        // width: 12rem;
        text-align: left;
        padding: 0.8rem 1rem;
        border-radius: 15px;
        &:focus {
          box-shadow: none;
        }
      }
      .cancel--btn {
        background-color: $gray-600;
        border-color: $gray-600;
        text-align: center;
      }

      .cancel--btn:hover {
        background-color: $gray-600;
        border-color: $gray-600;
        text-align: center;
      }
      .remove--btn {
        text-align: center;
      }
    }
  }
}

//logout page

.logout-main__page {
  width: 100%;
  height: 100%;
  background-color: $gray-700;
}
