.login-page__main {
  .login-bg-block {
    background: $gray-700;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-content-block {
    width: 100%;
    max-width: 655px;
    margin: 1.875rem auto;
    padding: 1.875rem 2.5rem 3.125rem;
    background: $brand-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    .logo-block {
      margin: 1rem 0;
      .logo--img {
        width: 16rem;
        height: auto;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .page-block__main {
      margin-top: 2rem;
      .title {
        margin-bottom: 1rem;
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
        color: $input-value;
        text-align: center;
      }
      .sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value;
        margin-bottom: 1rem;
        text-align: center;
      }
      .login-tab {
        min-width: 400px;
        .tab-block {
          padding-bottom: 0;
          .tab-nav-block {
            border-bottom: none;
            .active {
              border-color: $special-excel;
            }
          }
        }
      }
    }
  }
}

//loginPage
.login-form__block {
  .form-block--wrapper {
    .phone-number-input__form-group {
      margin-bottom: 2rem;
      border-radius: 15px;
    }
    .forgot-password-link--block {
      text-align: right;
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        font-weight: 600;
        font-size: 0.85rem;
        color: $semantic-info;
      }
    }
    .login-button--block {
      .login--btn {
        width: 100%;
        padding: 0.7rem;
        border-radius: 15px;
      }
    }
  }
}

//otpPage
.otp-page__main {
  text-align: center;
  color: $input-value;
  margin: 1rem 0;
  .form-block--wrapper {
    .phone-number-input__form-group {
      text-align: start;
      margin-bottom: 3rem;
    }
    .otp-button--block {
      .otp--btn {
        width: 100%;
        padding: 0.7rem;
      }
    }
  }
}

.password-toggle-button {
  float: right;
  position: relative;
  bottom: 65px;
  margin-right: 20px;
  z-index: 999;
  border: none;
  font-weight: 600;
  color: $brand-accent;
  outline: none;
}

.forgot-password-link--block {
  position: relative;
  bottom: 90px;
  z-index: 999;
  right: 0px;
}
