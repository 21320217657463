.access-denied {
  background: #16212c;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  place-items: center;
  place-content: center;
  text-align: center;
  img {
    height: 20vh;
    object-fit: contain;
  }
  .back-dashboard {
    color: #b21f2d;
    cursor: pointer;
    span {
      text-decoration: underline;
    }
  }
}
