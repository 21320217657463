// reserve-your-slot

.reserve-slot-page_main {
  .reserve-slot-main-block {
    .reserve-slot-block {
      .reserve-slot-search-box {
        .reserve-slot-btn {
          padding: 0.8rem 2rem;
        }

        .import-user--block {
          display: flex;
          align-items: center;

          .upload-file-block {
            .upload-file--input {
              display: none;
            }

            .upload-file-inner {
              cursor: pointer;

              .upload-file-text {
                font-style: normal;
                font-weight: bold;
                font-size: 0.875rem;
                line-height: 1.125rem;
                color: $brand-accent;
                padding-left: 0.8rem;
              }

              .import-btn__block {
                .btn:focus {
                  outline: none;
                  box-shadow: none;
                }

                .import_btn {
                  padding: 1rem 1.9rem;
                  border-color: $brand-secondary;
                  color: $brand-accent;
                  border-radius: 5px;
                  background: $brand-secondary;

                  .user-upload-file-box-icon {
                    color: $brand-accent;
                    margin-right: 1rem;
                  }

                  .user-upload-file-box-text {
                    font-style: normal;
                    line-height: 1.25rem;
                    color: $brand-accent;
                    font-weight: 400;
                  }
                }
              }
            }
          }

          .download-sample--block {
            display: flex;
            align-items: center;
            margin-left: 1rem;

            .download-sample-file {
              font-size: 0.9rem;
              color: $special-excel;
              cursor: pointer;
              font-weight: 500;
            }
          }
        }
      }

      .reserve-slot-table {
        .table-responsive {
          .record-list-table {
            .reserve-slot-icon {
              // color: lightskyblue;
              margin-right: 1rem;
              cursor: pointer;
            }

            .reserve-slot-table-editIcon {
              margin-right: 1.5rem;
              font-size: 1.2rem;
              color: $semantic-info;
              cursor: pointer;
            }

            .reserve-slot-table-deleteIcon {
              font-size: 1.2rem;
              color: $brand-accent;
              cursor: pointer;
            }

            .reserve-slot-delete-Btn {
              padding: 0.7rem 1rem;
            }
          }
        }
      }
    }
  }

  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//Rserver-your-slot Form

.reserve-slot-form-page-main {
  .reserve-slot-form-card {
    .card-body {
      padding: 2rem 2.5rem;
    }

    .reserve-slot-form__block {
      padding-bottom: 2rem;

      .reserve-slot-form__inner {
        padding: 1rem 0;
      }

      .reserve-slot-form-save--btn--block {
        .reserve-slot-form-save-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}

//user Form css
.reserve-form-body__block {
  padding: 1rem 2rem;

  .reserve-form--block {
    .row {
      position: relative;
      margin-bottom: 1em;
      .col.btn_del {
        position: absolute;
        text-align: right;
        .primary-button {
          padding: 0px;
        }
      }
    }
    .reserve-slot-add-btn {
      button {
        padding: 0px;
      }
    }
    .reserve-form-btn__block {
      margin-top: 1rem;

      .reserve-form-btn {
        padding: 1rem 1.5rem;
        font-weight: 500;
      }
      .margin_top {
        margin-top: 12px;
      }
    }
    .margin_top {
      margin-top: 12px;
      font-size: small;
    }
    .font-color {
      margin-bottom: 10px;
      color: $special-admin;
    }
    .time_sec {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      .custome_time {
        .form-error {
          bottom: 0px;
        }
      }
      .to_sec {
        padding: 8px;
        margin: auto;
      }
      .custome_time {
        display: flex;
        width: 45%;
        height: 50px;
        border-radius: 10px;
        background-color: transparent !important;
        .ct_selecter {
          padding: 0.5em;
          width: 100% !important;
          height: 100% !important;
          font-size: 14px !important;
          border-radius: 10px !important;
          color: $input-value;
          outline: none;
          border: 1px solid $input-border !important;
          background-color: transparent !important;
        }
      }
    }
  }
}

.reserve-solt-text-align {
  text-align: center;
}
.reserve-close-btn {
  width: 40px;
  padding: 0.1rem;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  text-align: center;
  &:hover {
    background-color: $light-table-header;
  }
}

.field--btn-prime {
  color: $semantic-info !important;
}

.reserve-slot-list-btn {
  height: 65%;
  background-color: $gray-600;
  border: $gray-600;
  &:hover {
    background-color: $brand-secondary;
    border: $gray-600;
  }
  &:focus {
    background-color: $brand-secondary;
  }
}

.reserve-slot-btns-sec {
  display: flex;
  justify-content: space-between;
  place-items: center;
  .reserve-slot-submit-btn {
    button {
      padding: 0.8rem 1rem !important;
    }
  }
}
@media (max-width: 768px) {
  .reserve-form-body__block {
    padding: 1em;
  }
}
