.booking-page__main {
  .booking-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $tab-active-color;
    .booking-header-name {
      font-size: 1.25rem;
    }
    .booking-close_btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $light-table-header;
      }
    }
  }
  .booking-body__block {
    margin-top: 1rem;
    .booking-inner--block {
      background: $brand-primary;
      border: 1px solid $tab-active-color !important;
      box-sizing: border-box;
      border-radius: 10px;
      .booking--header {
        background: $gray-600;
        border: 1px solid $brand-primary;
        box-sizing: border-box;
        border-radius: 8px 8px 0 0;
        padding: 1.5rem 1rem 1rem 1rem;
        border-bottom: 2px solid $tab-active-color;
        .booking-header-text {
          font-style: normal;
          font-weight: 600;
          font-size: 1.125rem;
          color: $input-value;
        }
      }
      .booking-content {
        padding: 1rem;
        .booking-content-inner {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          .booking-content-text {
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            color: $table-header;
            min-width: 13rem;
          }
          .booking-content-detail {
            font-style: normal;
            font-weight: 300;
            font-size: 0.9rem;
            line-height: 18px;
            color: $input-value;
            padding-left: 5rem;
          }
        }
      }
    }
  }
  .booking-close-button-box {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .booking-close-button {
      padding: 0.8rem 1.9rem;
    }
  }
}
