$button-primary: #be210b;
$input-value: #ffffff;
$button-primary-hover: #ab1b08;

.job-report-button {
  background: $button-primary;
  padding: 8px 12px;
  border-radius: 3px;
  border: none;
  color: $input-value;

  &:hover {
    background: $button-primary-hover;
  }
}

.job-report-heading {
  color: $input-value;
}

.job-modal {
  color: $input-value;
}

.job-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    background: none;
    border: none;
    color: $button-primary;
    font-size: 1.5em;
    cursor: pointer;
  }
}

.job-modal-body,
.job-modal-footer {
  border: none;
}

.responsive-button {
  display: inline-block;
  width: auto;
  padding: 8px 12px;
  font-size: 1em;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 12px;
    font-size: 1.4em;
  }
}