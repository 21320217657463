.serverOperation_main_page {
  background: $gray-700;
  border-radius: 20px;
  border-radius: 20px;
  .tab--block {
    padding: 20px 0;
    display: flex;
    border-radius: 20px;
    border-radius: 20px;
    .tab-nav--block {
      width: 297px;
      display: flex;
      flex-direction: column;
      background: $brand-primary;
      margin-left: 1rem;
      border-radius: 20px;
      overflow: hidden;
      padding: 10px;
      .tab-nav__item {
        border-bottom: 2px solid transparent;
        padding: 0.7rem 1.5rem;
        color: $gray-200;
        font-size: 0.9rem;
        font-weight: 500;
        text-decoration: none;
        border-radius: 20px;
        margin: 2px 0px;
        &:hover {
          background-color: $gray-700;
        }
      }
      .active {
        color: $input-value;
        font-weight: 500;
        background: $tab-active-color;
      }
      &__right {
        margin-left: auto;
      }
    }
    .tab-content-block {
      width: 100%;
      .tab-content___item {
        display: none;
        padding: 0 1.5rem;
      }
      .active {
        display: block;
      }
    }
  }
  @media (max-width: 768px) {
    .tab--block .tab-nav-block .tab-nav__item {
      margin: 0 0.5rem;
    }
    .primary-button {
      padding: 0.4rem 0.5rem;
    }
  }
  @media (max-width: 1024px) {
    .tab--block .tab-nav-block .tab-nav__item {
      padding: 1rem 0;
    }
    .primary-button {
      padding: 0.4rem 0.8rem;
    }
    .tab--block {
      flex-direction: column;

      .tab-nav--block {
        flex-direction: row;
        overflow: scroll;
        width: 580px;
        padding: 1rem;
        white-space: nowrap;
        margin-bottom: 1rem;
        border-radius: 5px;
      }
    }
  }
}
