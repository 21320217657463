.maindiv {
  width: 100%;
  background-color: $brand-primary;
  padding: 1rem;
  border-radius: 20px;
}

.insidewrap {
  display: flex;
  margin-top: 1.5rem;

  select {
    background-color: $gray-700;
    color: $input-value;
    outline: none;
    border: solid 2px $gray-700;
    padding: 10px 5px;
    margin-right: 10px;
    width: 30%;
    border-radius: 5px;
  }
}

.tablearea {
  a {
    background-color: $brand-accent;
    color: $input-value;
    outline: none;
    border: 0px;
    padding: 0.8rem 1rem;
    margin-right: 10px;
    border-radius: 15px;
    text-decoration: none;

    &:hover {
      background-color: $button-primary-hover;
      color: $input-value;
      outline: none;
      border: 0px;
      padding: 0.8rem 1rem;
      margin-right: 10px;
      border-radius: 15px;
    }
  }
}
