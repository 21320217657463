.setting-page__main {
  .page--info {
    display: flex;
    align-items: center;
    .user-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .user-name {
        font-style: normal;
        font-weight: 500;
        font-size: 1.625rem;
        line-height: 2.063rem;
        color: $input-value;
        margin-bottom: 0.7rem;
      }
      .user-info {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $input-value;
      }
    }
  }
  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// Profile-tab
.profile-details__main {
  .profile-details-inner {
    .profile--content {
      margin-bottom: 1rem;
      .profile-details--wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }
}

//change Password
.change-password-form_main {
  padding: 0 2rem;
  .change-password-form_block {
    .change-password-form__inner {
      .form-input-group {
        margin-bottom: 1.7rem;
      }
    }
    .change-password-save--btn--block {
      .save--btn {
        padding: 1rem 2.5rem;
        background: $brand-accent;
        border-radius: 8px;
      }
    }
  }
}

//Roles&Access tab
.role-access-page__main {
  .table-responsive {
    overflow: inherit !important;
    .role-access-name-list {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      .user-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.7rem;
        width: 1.7rem;
      }
    }
    .role-access-role {
      .roles-select__box {
        margin: 0;
        width: 14rem;
        .react-select {
          .react-select__control {
            border: 1px solid $gray-200;
            border-radius: 4px !important;
            .react-select__value-container {
              padding: 0.1rem 0.5rem;
            }
          }
        }
      }
    }
    .role-access-charging-station-icon {
      color: lightskyblue;
      margin-right: 1rem;
      cursor: pointer;
    }
    .role-access-delete-icon {
      cursor: pointer;
      svg {
        height: 2.5rem;
        width: 1.375rem;
        color: $brand-accent;
      }
    }
    @media (max-width: 1024px) {
      .css-gzpaqu-container {
        min-width: 10rem;
      }
    }
  }
  .loader-block___ {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//stationWiseFilter
.station-wise-filter_page__main {
  .station-wise-filter_page__main--card {
    .filter-btn__block {
      display: flex;
      text-align: right;
      margin-bottom: 1rem;
      .filter_btn {
        padding: 0.9rem 5rem;
        font-weight: 500;
      }
      .reset--block {
        padding: 0rem 1.7rem;
        color: $gray-200;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: $status-breathing-red;
        }
        .reset_btn {
          padding: 0.8rem 1.5rem;
          font-weight: 500;
          .reset--icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

// Password Progrees Bar
.password-strength-bar {
  height: 5px;
  background: white;
  margin-top: -0.5em;
  border-radius: 3px;
  transition: background-color 0.2s ease-out;

  .strength-bar {
    height: 100%;
    border-radius: inherit;
    transition: width 0.5s ease-in-out;
    margin-bottom: 10px;
  }

  .strength-weak {
    width: 33%;
    background: linear-gradient(to right, #ff4d4d, #ffa500);
  }

  .strength-moderate {
    width: 66%;
    background: linear-gradient(to right, #ff4d4d, #ffa500);
  }

  .strength-strong {
    width: 100%;
    background: linear-gradient(to right, #ff4d4d, #ffa500, #00ff00);
  }

  .strength-weak + .strength-text::after {
    content: 'Weak';
    color: $brand-accent;
  }

  .strength-moderate + .strength-text::after {
    content: 'Moderate';
    color: #ffa500;
  }

  .strength-strong + .strength-text::after {
    content: 'Strong';
    color: #00ff00;
  }

  .strength-text {
    font-size: 0.8rem;
    position: absolute;
    top: 4em;
    right: 2em;
    font-weight: 700;
  }
}

.password_div {
  position: relative;
  margin-bottom: 1em;
}

.password_div.resetpass .password-strength-bar .strength-text {
  right: 12px;
  top: auto;
}

.icon-and-message {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .icon-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .message-container {
    font-size: 0.875rem;
    color: red;
  }

  .check-icon,
  .cross-icon {
    font-size: 1rem;
    margin-right: 5px;
  }

  .check-icon {
    color: #19f600;
  }

  .cross-icon {
    color: red;
  }
}
