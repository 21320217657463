.form-input-group {
  margin-bottom: 1.5rem;

  .input-label {
    font-size: 0.875rem;
    margin-bottom: 0.7rem;
    color: $input-label;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;

    .require--star {
      color: $brand-accent;
      font-size: 0.95rem;
      margin-left: 3px;
    }
  }

  .input-group {
    width: auto;
    position: relative;

    .icon-group {
      border: 0;
      border-radius: 0;
      position: absolute;
      padding: 0.5rem;
      background: transparent;
      color: $input-value;
      opacity: 0.7;
      font-size: 0.9rem;
      z-index: 9;
    }

    .uploaded-image {
      padding-right: 1rem;

      img,
      svg {
        width: 5rem;
        height: 3.375rem;
      }
    }

    .pre-group {
      padding-left: 1rem;
      top: 0;
      left: 0;
      bottom: 0;
    }

    .post-group {
      padding-right: 1rem;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .text-group {
      width: auto !important;
      max-width: 5rem !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .pre-icon-input {
      padding-left: 2.5rem !important;
    }

    .pre-text-input {
      padding-left: 5rem !important;
    }

    .post-icon-input {
      padding-right: 2.5rem !important;
    }

    .post-text-input {
      padding-right: 5rem !important;
    }

    .text-input {
      border: 1px solid $input-border;
      color: $input-value;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.125rem;
      background: transparent;
      box-sizing: border-box;
      border-radius: 15px !important;
      padding: 1rem;
      padding-right: 50px;
      &:focus {
        box-shadow: none !important;
        border:solid 1px $input-focus;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: $input-disabled;
      }
    }

    input[type='time'],
    input[type='date'] {
      padding: 0.7rem 1rem;

      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        font-size: 1.2rem;
        filter: invert(50%) sepia(50%) saturate(3200%) hue-rotate(130deg) brightness(100%) contrast(10%);
        -webkit-filter: invert(50%) sepia(50%) saturate(3200%) hue-rotate(130deg) brightness(100%) contrast(10%);
      }
    }
  }
}

// profile-pic-input css
.profile-block__main {
  display: inline-block;
  // padding: 1rem;
  margin-right: 1rem;

  .profile-block__inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .profile-img--inner {
      margin-bottom: 1.5rem;

      .picture__block {
        position: relative;
        height: 9rem;
        width: 9rem;
        border-radius: 15px;
        box-shadow: 0px 0px 6px 1px #ffffff00;
        padding: 17px;


        .profile-loader--block {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          opacity: 0.6;
        }

        img {
          // height: 100%;
          // width: 100%;
          border-radius: 50%;
        }

        .camera-icon__block {
          position: absolute;
          bottom: -18px;
          left: 37%;
          padding: 0.5rem;
          display: inline-flex;
          border-radius: 50%;
          background-color: $brand-primary;
          box-shadow: 0px 0px 6px 1px #80808000;

          .camera-icon {
            height: 1.3rem;
            width: 1.3rem;
            color: $brand-accent;
          }

          .file-input--block {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;

            .file-input {
              width: 3rem;
              opacity: 0;
            }
          }
        }
      }
    }

    .bottom-content {
      font-size: 0.9rem;
      color: $input-label;
    }
  }
}