// Core Brand Colors
$brand-primary: #1b2a38;
$brand-secondary: #223343;
$brand-accent: #be210b;

// Semantic Colors
$semantic-success: #0f9b72;
$semantic-warning: #f2de10;
$semantic-error: #dc4949;
$semantic-info: #4975F2;

// Grays
$gray-50: #F7F7F8;
$gray-100: #B9C8D3;
$gray-200: #8d949b;
$gray-300: #5f7384;
$gray-400: #3b4a56;
$gray-500: #29343e;
$gray-600: #182532;
$gray-700: #15212b;
$gray-800: #1C2A3B;

// UI Elements

$activity-icon-block-border: #394f63;

$input-label: #ffffff80;
$input-border: #3b4a56;
$input-value: #ffffff;
$input-disabled: #223444;
$input-focus: #979797;

$button-primary: #be210b;
$button-primary-hover: #bb2d3b;
$button-secondary: #36303a;
$button-secondary-text: #f5604c;

$sidebar-menu-item: #8d9dac;
$sidebar-hover-bg: #1e3349;

$table-header: #ffffff80;
$table-sub-row-bg: #60606021;

$scrollbar: #c3c2c566;

$toggle-default: #ccc;
$toggle-checked: #0f9b72;

$tab-active-color: #1e3249;
$status-block-hover: #1b2a38ad;


// Status Colors
$status-charger-yellow: #f2de10;
$status-charger-icon-bg: #e85c481a;
$status-breathing-blue: #3c7cdd1a;
$status-breathing-red: #be210b21;

// Light Mode Colors
$light-bg-primary: #f0f0f0;
$light-bg-secondary: #ffffff;
$light-bg-content: #eef7ff;
$light-text-primary: #333333;
$light-text-secondary: #8d949b;

// Light Mode UI Elements
$light-input-label: #333333;
$light-input-border: #dddddd;
$light-input-value: #333333;
$light-input-disabled: #999999;
$light-sidebar-hover: #f0faff;
$light-sidebar-after-hover: #eef7ff;
$light-table-header: #000000;

// Special Purpose Colors
$special-rating: #fbaa26;
$special-excel: #0f9b72;
$special-admin: #808080;
$special-billing: #ffffff1a;

// Profile Type Colors
$profile-types: (#1C2A3B, #363CAA, #800DA3, #A12941, #FB6107, #F7AD00, #326771, #129387, #608FC9);

// User Groups
$user-group-1: #365173;
$user-group-2: #8B1D68;
$user-group-3: #B941DD;
$user-group-4: #68CCDF;
$user-group-5: #565EFC;
$user-group-6: #F3486A;
$user-group-7: #30B04D;
$user-group-8: #BADA4E;
$user-group-9: #FFCB47;

// Brand Secondary Colors
$brand-secondary-1: #42648D;
$brand-secondary-2: #A12941;
$brand-secondary-3: #F3486A;
$brand-secondary-4: #449EAE;
$brand-secondary-5: #8C56FC;
$brand-secondary-6: #FF7D00;
$brand-secondary-7: #30B06B;
$brand-secondary-8: #00A0E9;
$brand-secondary-9: #4600D7;

// Member Types
$member-frequent-visitor: #a12941;
$member-home: #8f8f8f;
$member-jm-office: #129387;
$member-m-campus: #608fc9;
$member-m-maritime: #1c2a3b;
$member-visitor: #cc173b;

// Space Colors
$spaces: (#1c2a3b, #59636F, #88AAD3, #7E8995, #9DA9B5, #ededed, #E3B2BF, #D096A1, #BA6B7A, #a12941);

// Layout
$header-height: 4rem; // 64px
$sidebar-width: 18rem; // 288px
$toolbar-width: 20.25rem; // 324px

// Spacing
$spacing-tiny: 0.5rem; // 8px
$spacing-small: 1rem; // 16px
$spacing-normal: 1.625rem; // 26px
$spacing-outline: 1.75rem; // 28px

// Font Weights
$font-weight-normal: 300;
$font-weight-bold: 400;

// Font Sizes
$font-size-small: 0.75rem; // 12px
$font-size-normal: 0.875rem; // 14px
$font-size-header: 1.5rem; // 24px

$spacing-xs: 0.25rem;
$spacing-sm: 0.5rem;
$spacing-md: 1rem;
$spacing-lg: 2rem;

$size-xs: 1rem;
$size-sm: 1.2rem;
$size-md: 1.5rem;
$size-lg: 3rem;

$font-size-xs: 0.875rem;
$font-size-sm: 1rem;
$font-size-md: 1.125rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$border-radius-sm: 0.5rem;
$border-radius-md: 1rem;
$border-radius-lg: 1.5rem;

$border-width-sm: 1px;
$border-width-md: 2px;
