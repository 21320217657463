.rfid-page-main {
  .rfid-form-card {
    .rfid-form__block {
      padding-bottom: 0.6rem;
    }
    .import-rfid--block {
      display: flex;
      align-items: center;
      .upload-file-block {
        .upload-file--input {
          display: none;
        }
        .upload-file-inner {
          cursor: pointer;
          .upload-file-text {
            font-style: normal;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: #e12f17;
            padding-left: 0.8rem;
          }
          .import-btn__block {
            .btn:focus {
              outline: none;
              box-shadow: none;
            }
            .import_btn {
              padding: 0.8rem 1rem;
              border-color: $brand-secondary;
              color: #e12f17;
              border-radius: 15px;
              background: $brand-secondary;
              text-transform: capitalize;
              .rfid-upload-file-box-icon {
                color: #e12f17;
                margin-right: 1rem;
              }
              .rfid-upload-file-box-text {
                font-style: normal;
                line-height: 1.25rem;
                color: #e12f17;
                font-weight: 400;
              }
            }
          }
        }
      }
      .download-sample--block {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        .download-sample-file {
          font-size: 0.9rem;
          color: #58d37d;
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
  }
  .rfid-btn-block {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    .btn:focus {
      outline: none;
      box-shadow: none;
    }
    .rfid-btn {
      background: $brand-secondary;
      width: 17.5rem;
      height: 3.5rem;
      border-color: $brand-secondary;
      color: #e12f17;
      font-size: 0.9rem;
    }
  }
  .rfid-table-btn__block {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    .table_btn {
      width: 12.563rem;
      height: 3.5rem;
    }
  }
  .rfidList-table {
    .rfid {
      .list-icon {
        display: flex;
        align-items: center;
        ul {
          list-style-type: none;
          display: flex;
          gap: 15px;
          padding-left: 0px;
          li {
            svg {
              cursor: pointer;
            }
          }
        }
      }
    }
    .loader--block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//rfid edit list css

.rfid-edit-list-page__main {
  .rfid-edit-list-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #2e3841;
    .rfid-edit-list-header-name {
      font-size: 1rem;
    }
    .close___btn {
      padding: 0.1rem;
      border-radius: 15px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .rfid-edit-list-body__block {
    padding: 1rem 2rem;
    .rfid-edit-list--block {
      .rfid-edit-list-table {
        .table-responsive {
          .record-list-table {
            tbody {
              tr {
                td {
                  background: #16212b;
                  border-right: 1px solid $gray-500;
                  .form-input-group {
                    margin-bottom: 0rem;
                  }
                }
              }
            }
          }
          .rfid-edit-list-btn__block {
            margin-bottom: 1rem;
            display: flex;
            text-align: right;
            margin-top: 2rem;
            .rfid-edit-list_btn {
              padding: 0.8rem 1rem;
              font-weight: 500;
            }
            .cancel-button-block {
              margin-left: 0.5rem;
              .cancel____btn {
                margin-right: 1rem;
                background-color: transparent;
                padding: 0.8rem 1rem;
                border-color: transparent;
                color: $brand-accent;
                background-color: $button-secondary;
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

//rfid Request

.rfid-request_main {
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.report-excel-icon {
  transition: 0.2s;
  position: relative;
  cursor: pointer;

  &:hover svg {
    color: rgb(255, 0, 0) !important;
  }
}
