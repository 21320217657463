.pagination-block__main {
  margin: 1rem 0;
  .pagination-block--inner {
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination-data-count--block {
      color: $gray-300;
      font-size: 0.9rem;
      font-weight: 600;
    }
    .pagination-btn--block {
      display: flex;
      align-items: center;
      .arrow--btn {
        padding: 0.4rem 1.7rem;
        color: $gray-300;
        background: $brand-secondary;
        border-radius: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 0.7rem;
        }
        svg {
          width: 2rem;
          height: 2rem;
        }
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
