.existing-pricing__main {
  .existing-pricing--block {
    .pricing-block-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      .pricing-block-text {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value;
      }
      .add-new-price {
        cursor: pointer;
        .add-icon {
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          margin-right: 0.5rem;
          line-height: 1.875;
          text-align: center;
          color: #e02f17;
        }
        .add-model-text {
          font-style: normal;
          font-weight: bold;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #e12f17;
        }
      }
    }
    .pricing-list-table {
      .edit-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: $semantic-info;
        cursor: pointer;
      }
      .delete-icon {
        font-size: 1.2rem;
        color: $brand-accent;
        cursor: pointer;
      }
    }
  }
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// pricing-form-css
.pricing-form-page__main {
  .pricing-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .pricing-header-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .pricing-form-body__block {
    padding: 1rem 0;
    .pricing-form--block {
      .schedule-block__field-inputs {
        .field--btn {
          border-color: transparent;
          background-color: transparent;
          color: $brand-accent;
          font-size: 1rem;
          padding: 0;
          font-weight: 600;
          display: flex;
          align-items: center;
          &:focus,
          &:active {
            box-shadow: none;
          }
          .plus--icon {
            margin-right: 0.2rem;
          }
        }
      }
      .time-picker__input-block {
        .time--input-box-block {
          .form-input-group {
            margin-bottom: 0;
          }
          .input-box--divider {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .form-input-group {
        .pre-text-input {
          padding-left: 2.5rem !important;
        }
      }
      .form-btn--block {
        text-align: right;
        .pricing-form-btn {
          padding: 0.8rem 1rem;
          font-weight: 500;
        }
        .cancel-btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $brand-accent;
          &:hover {
            background-color: $button-secondary;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
