.has-dropdown {
  position: relative;
  display: inline-block;
  background: $gray-600;
  border: 3px solid $gray-600;
  box-sizing: border-box;
  border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  a {
    text-decoration: none;
    cursor: pointer;
  }
  .popup {
    position: absolute;
    width: 100%;
    min-width: 200px;
    left: 0;
    top: 100%;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: -2;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    overflow-y: auto;
    background: $brand-secondary;
    padding: 5px;
    max-height: 0;
    box-shadow: 0 4px 10px rgba(52, 49, 49, 0.25);
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0px 0px 50px rgb(36, 36, 36);
    .popup-container {
      ul {
        padding: 0;
        margin: 0;
        li {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          transition: 0.2s;
          &:hover {
            background-color: $brand-primary;
          }
          &.label {
            padding: 0.625rem 0.938rem 0;
            font-weight: 300;
            font-size: 0.75rem;
            color: #e8e8e9;
          }
          &.item {
            color: $input-value;
            font-weight: 500;
            font-size: 0.875rem;
            padding: 0.625rem 0.938rem;
            cursor: pointer;
            span {
              display: inline-flex;
              align-items: center;
            }
            em {
              margin-right: 0.313rem;
            }
            &.divider {
              height: 1px;
              opacity: 0.2;
              border-top: 1px solid #cfcfcf;
              padding: 0 !important;
              margin: 0.75rem 0;
            }
            &.selected {
              background: $brand-primary;
              &:hover {
                background: $brand-primary;
              }
            }
            &:hover {
              background: $brand-primary;
            }
          }
          &.text-red {
            color: #f85353;
            &:hover,
            &.active,
            &:active {
              color: $input-value;
            }
          }
        }
      }
    }
    &.drop-top {
      top: auto;
      bottom: 100%;
    }
  }
  .bottom-left {
    top: 20px !important;
    bottom: unset !important;
    right: 0 !important;
    left: unset !important;
  }
  .from-top-right {
    top: unset !important;
    bottom: 15px !important;
    left: unset !important;
    right: 0 !important;
  }
  &.open {
    .popup {
      z-index: 100;
      visibility: visible;
      opacity: 1;
      pointer-events: all;
      max-height: 360px;
      display: block;
      transition: 0.2s;
    }
  }
}
