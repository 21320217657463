.publicUsers-page {
  .fc .fc-toolbar {
    display: flex;
    // justify-content: center;
    .fc-toolbar-chunk {
      .fc-today-button {
        display: none;
      }
    }
  }
  .fc-scrollgrid-sync-inner {
    a {
      color: #747e86;
      text-decoration: none;
    }
  }
  .fc-daygrid-day-top {
    a {
      color: $input-value;
      text-decoration: none;
    }
  }
  .fc .fc-daygrid-day-frame {
    cursor: pointer;
  }
  .fc-h-event {
    display: block;
    border: none;
    background-color: #1a2a38;
  }
  .fc-daygrid-day-events {
    min-height: 4rem !important;
    margin-top: 1.3rem !important;
    @media screen and (max-width: 1440px) {
      margin-top: 0rem !important;
    }
    .fc-daygrid-event-harness {
      a {
        display: flex;
        justify-content: center;
      }
    }
    .fc-event-title {
      font-size: 1.2rem;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .fc-scrollgrid-sync-inner {
    background: #1a2a38;
  }
  @media screen and (max-width: 1440px) {
    .fc-event-title {
      font-size: 0.9rem !important;
      font-weight: 600 !important;
    }
  }
}

//model
.public-user-model_main_content {
  display: flex;
  padding: 1.5rem;
  .title_name {
    flex-grow: 1;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .table-close-btn {
    padding: 0.1rem;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: black;
    }
  }
}
.public-user-table {
  padding: 1.5rem 2rem 1.5rem 2rem;
  .table-responsive {
    @media screen and (max-width: 1440px) {
      max-width: none !important;
    }
  }
}
.public-user-empty-data-block {
  text-align: center;
  font-size: 0.9rem;
  color: $sidebar-menu-item;
}
.custom_select {
  width: 8em;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  top: 40px;
  .react-select {
    .react-select__control {
      .react-select__value-container {
        padding: 0px 0.5rem;
      }
    }
  }
}
