//rfid-request Modal

.rfid-request-details-page__main {
  .rfid-request-details-page-inner {
    .rfid-request-details-header__block {
      padding: 1rem 0;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #2e3841;
      .rfid-request-details-header-block_inner {
        font-size: 1.25rem;
      }
      .close___btn {
        padding: 0.1rem;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background-color: black;
        }
      }
    }
    .rfid-request-details-body__block {
      padding: 1rem 2rem;
      .rfid-request-details-body-block__inner {
        //display: flex;
        //justify-content: flex-start;
        border: 1px solid $gray-500;
        border-radius: 0.5rem;
        margin-bottom: 1.5rem;
        padding: 0 1rem;
        .content-wrapper {
          display: flex;
          align-items: center;
          .rfid-request-details-data {
            width: 12.5rem;
            padding: 1rem;
          }
          .rfid-request-details-name {
            padding: 1rem;
          }
          .rfid-request-status-block {
            color: #c79f32;
          }
        }
      }
      .rfid-request-balance--block {
        .rfid-request-balance-text {
          font-size: 0.9rem;
          color: $sidebar-menu-item;
        }
      }
      .rfid-request-details--block {
        padding-top: 3rem;
        .form-label {
          color: $input-value;
        }
        .react-request-select__placeholder {
          color: $input-value;
        }
        .form-btn__block {
          display: flex;
          justify-content: center;
          text-align: right;
          margin-top: 1rem;
          .form_btn {
            padding: 0.8rem 1.7rem;
            font-weight: 500;
            width: 12.563rem;
            height: 3.5rem;
          }
        }
        .show-on-rfid-block {
          margin-top: 2rem;
          text-align: center;
          .show-on-rfid-checkbox-input__block {
            .show-on-rfid--label {
              font-size: 1rem;
              margin-bottom: 0.7rem;
              color: $input-label;
              font-weight: 500;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}
