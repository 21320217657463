.rfid-details-page__main {
  .rfid-details-page-inner {
    .rfid-details-header__block {
      padding: 1rem 0;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid $tab-active-color;
      .rfid-details-header-block_inner {
        font-size: 1.25rem;
      }
      .close___btn {
        padding: 0.1rem;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background-color: $light-table-header;
        }
      }
    }
    .rfid-details-body__block {
      padding: 1rem 2rem;
      .rfid-details-body-block__inner {
        //display: flex;
        //justify-content: flex-start;
        border: 1px solid $gray-500;
        border-radius: 0.5rem;
        margin-bottom: 1.5rem;
        padding: 0 1rem;
        .content-wrapper {
          display: flex;
          align-items: center;
          .rfid-details-data {
            width: 12.5rem;
            padding: 1rem;
          }
          .rfid-details-name {
            padding: 1rem;
          }
          .rfid-status-block {
            color: $status_charger-yellow;
          }
        }
      }
      .rfid-balance--block {
        .rfid-balance-text {
          font-size: 0.9rem;
          color: $sidebar-menu-item;
        }
      }
      .rfid-details--block {
        padding-top: 3rem;
        .form-label {
          color: $input-value;
        }
        .react-select__placeholder {
          color: $input-value;
        }
        .form-btn__block {
          display: flex;
          justify-content: center;
          text-align: right;
          margin-top: 1rem;
          .form_btn {
            padding: 0.8rem 2rem;
            font-weight: 500;
          }
        }
        .show-on-rfid-block {
          margin-top: 2rem;
          text-align: center;
          .show-on-rfid-checkbox-input__block {
            .show-on-rfid--label {
              font-size: 1rem;
              margin-bottom: 0.7rem;
              color: $input-label;
              font-weight: 500;
              margin-right: 1rem;
            }
          }
        }
        .unassigned-rfid-btn {
          margin-left: 1rem;
          padding: 0.7rem 2rem;
          background-color: $brand-accent;
          display: flex;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            color: $input-value;
            background-color: $button-primary-hover;
            border-color: $brand-accent;
          }
        }
      }
    }
  }
}
